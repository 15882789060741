import { Box, Button, Flex, Spacer, Divider, FormControl, FormLabel, Switch, Table, 
    Thead, Tbody, Tr, Th, Td, TableContainer, Select, HStack, Tooltip, CircularProgress, AlertDialog, 
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, 
    useDisclosure, useToast } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { DeleteIcon } from '@chakra-ui/icons'
//import useRowsProducts from "../hooks/useRowsProducts";
import useColumnsProducts from "../hooks/useColumnsProducts";
import '../css/stylesheet.css'
import { GlobalFilter } from './GlobalFilter'
import { useEffect, useState, useRef } from 'react'
import ProductAdd from './ProductAdd'
import ProductEdit from './ProductEdit'
import ProductUpdatePrice from './ProductUpdatePrice'
import ProductPurchases from './ProductPurchases';
import ProductSales from './ProductSales';
import ProductsReports from './ProductsReports'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const Products = () => {
    const [isActive, setIsActive] = useState(false)
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
    //const [subcategories, setSubcategories] = useState([])
    //const [subcategory, setSubcategory] = useState(null)
    const [category, setCategory] = useState(null) //La agregué en reemplazo de la línea anterior
    const [submitModal, setSubmitModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { isOpen: isOpenRemoveProduct, onOpen: onOpenRemoveProduct, onClose: onCloseRemoveProduct } = useDisclosure()
    const cancelRefRemoveProduct = useRef()
    const [product, setProduct] = useState()
    const toast = useToast()

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/product/allProducts/" + isActive.toString() + "/" + /*subcategory*/category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrProducts = data.map(product => {
                return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    stock: product.stock,
                    priceView: "$" + parseFloat(product.price).toFixed(2),
                    ubication: product.ubication,
                    code: product.code,
                    isActive: product.isActive.toString(),
                    avgIva: parseFloat(product.avgIva).toFixed(2),
                    category: product.category,
                    //subcategory: product.subcategory
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            /*authService.logout()
            navigate("/");
            window.location.reload();*/
        })

        fetch("https://api.veterinariacajeao.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
        const arrCategories = data.map(category => {
            return { 
                id: category._id,
                name: category.name
            }
        })
        setCategories(arrCategories)
        })
        .catch(error => {
            console.log(error)
            /*authService.logout()
            navigate("/");
            window.location.reload();*/
        })

        

    }, [isActive, /*subcategory*/category, submitModal])

    const columns = useColumnsProducts();
    //const data = useRowsProducts();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id", "code", "isActive", "avgIva", "category"/*, "subcategory"*/], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
      } = table;

      const { globalFilter } = state

      const handleChange = () => {
        setIsActive(!isActive)
      }

      const handleChangeCategory = (e) => {
        const accessToken = localStorage.getItem("user")

        if (e.target.value === "") {
            //setSubcategories([])
            //setSubcategory(null)
            setCategory(null)
            return
        }

        setCategory(e.target.value) //La agregué cuando comenté las dos líneas anteriores
      
        /*  fetch("https://api.veterinariacajeao.com.ar/api/subcategory/category/" + e.target.value, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
          .then(response => response.json())
          .then(data => {
            const arrSubcategories = data.map(subcategory => {
              return { 
                  id: subcategory._id,
                  name: subcategory.name
              }
          })
          setSubcategories(arrSubcategories)
          })
          .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
          })*/
      }

      /*const handleChangeSubcategory = (e) => {
        if (e.target.value === "") {
            setSubcategory([])
            return
          }

        setSubcategory(e.target.value)
      }*/

      const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
      }
      
    const handleRemoveProduct = (idProduct) => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/product/moviments/" + idProduct, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.moviments === true) {
                toast({
                    title: "No puede eliminar el producto porque tiene movimientos cargados",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
            } else {
                setProduct(idProduct)
                onOpenRemoveProduct()
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const removeProduct = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/product/" + product, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "El producto se eliminó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        setSubmitModal(!submitModal)
        onCloseRemoveProduct()
    }

    return(
        <>
        <Box className="content">
            {/*<ButtonGroup size='sm' isAttached>
                <Button colorScheme="whatsapp" onClick={onOpen}>Agregar</Button>
                <IconButton aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
    </ButtonGroup>*/}
            <HStack>
                <ProductAdd handleSubmitModal={handleSubmitModal}/>
                <ProductUpdatePrice data={data} handleSubmitModal={handleSubmitModal}/>
                <ProductsReports/>
            </HStack>

            <Box paddingTop="10px">
                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
            </Box>

            <Flex paddingTop="10px">
                <Box>
                    <HStack>
                        <FormLabel>Categoría:</FormLabel>
                        <Select placeholder='-Selecciona-' onChange={(e) => handleChangeCategory(e)}>
                        {categories.map(category => {
                            return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                            )})}
                        </Select>
                        {/*<FormLabel>Subcategoría:</FormLabel>
                        <Select placeholder='-Selecciona-' onChange={(e) => handleChangeSubcategory(e)}>
                        {subcategories.map(subcategory => {
                            return (
                            <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                        )})}
                            </Select>*/}
                    </HStack>
                </Box>
                <Spacer/>
                <Box>
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='actives' mb='0'>Sólo activos</FormLabel>
                        <Switch id='actives' defaultChecked={isActive} onChange={handleChange} />
                    </FormControl>
                </Box>
            </Flex>

            <Divider paddingTop="15px"/>

            {loading && <Box paddingTop="15px" textAlign='center'> 
                <CircularProgress isIndeterminate color='green.300' />
            </Box>}

            {!loading && <TableContainer paddingTop="15px">
                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                    <Thead>
                        {
                            // Recorremos las columnas que previamente definimos
                            headerGroups.map(headerGroup => (
                                // Añadimos las propiedades al conjunto de columnas
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Recorremos cada columna del conjunto para acceder a su información
                                    headerGroup.headers.map((column) => (
                                    // Añadimos las propiedades a cada celda de la cabecera
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                        {
                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                        column.render("Header")
                                        }
                                    </Th>
                                    ))
                                }
                                </Tr>
                            ))
                        }
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                            // Recorremos las filas
                            page.map(row => {
                                // Llamamos a la función que prepara la fila previo renderizado
                                prepareRow(row);
                                return (
                                // Añadimos las propiedades a la fila
                                <Tr {...row.getRowProps()}>
                                    {
                                    // Recorremos cada celda de la fila
                                    row.cells.map((cell) => {
                                        // Añadimos las propiedades a cada celda de la fila
                                        return (
                                        <Td {...cell.getCellProps()}>
                                            {
                                            // Pintamos el contenido de la celda
                                            cell.render("Cell")
                                            }
                                        </Td>
                                        );
                                    })
                                    }
                                    <Td>
                                        <HStack>
                                            {/*<Tooltip label='Editar'><Button colorScheme='yellow' onClick={() => handleClick(row)}><EditIcon/></Button></Tooltip>*/}
                                            <ProductPurchases idProduct={row.values._id} nameProduct={row.values.name} handleSubmitModal={handleSubmitModal}/>
                                            <ProductSales idProduct={row.values._id} nameProduct={row.values.name} handleSubmitModal={handleSubmitModal}/>
                                            <ProductEdit idProduct={row.values._id} idCategory={row.values.category} idSubcategory={row.values.subcategory} handleSubmitModal={handleSubmitModal}/>
                                            <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => handleRemoveProduct(row.values._id)}><DeleteIcon/></Button></Tooltip>
                                        </HStack>
                                    </Td>
                                </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>}

            {!loading && <Box paddingTop="10px" textAlign="center">
                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
            </Box>}

            {/*<AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar producto
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            No puede eliminar el producto porque tiene movimientos cargados
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} ml={3}>
                                Ok
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
                    </AlertDialog>*/}

            <AlertDialog
                    isOpen={isOpenRemoveProduct}
                    leastDestructiveRef={cancelRefRemoveProduct}
                    onClose={onCloseRemoveProduct}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar producto
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar el producto?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRefRemoveProduct} onClick={onCloseRemoveProduct}>
                                No
                            </Button>
                            <Button colorScheme='blue' onClick={removeProduct} ml={3}>
                                Si
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            
        </Box>

        </>
        

    )
}

export default Products