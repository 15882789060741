import { TYPES } from '../actions/despatchAction'

export const despatchInitialState = {
    data: []
}

export function despatchReducer(state, action) {
    switch (action.type) {
        case TYPES.ADD_TO_DESPATCH: {
            let product = state.data.find(obj => obj._id === action.payload._id)
            
            return product
            ? {
                ...state,
                data: state.data.map(obj => 
                    obj._id === product._id
                    ? {...obj, quantity: parseFloat(action.payload.quantity).toFixed(2)}
                    : {...obj}),
            }
            : {
                ...state,
                data: [...state.data, {...action.payload, quantity: parseFloat(action.payload.quantity).toFixed(2)} ]
            }
        }
        case TYPES.REMOVE_TO_DESPATCH: {
            return {
                ...state,
                data: state.data.filter(obj => obj._id !== action.payload)
            }
        }
        case TYPES.CLEAR_DESPATCH: {
            return despatchInitialState
        }
        default:
            return state
    }
}