import { TYPES } from '../actions/cartAction'

export const cartInitialState = {
    data: []
}

export function purchasePlanReducer(state, action) {
    switch (action.type) {
        case TYPES.ADD_TO_CART: {
            let product = state.data.find(obj => obj._id === action.payload._id)

            return product
            ? {
                ...state,
                data: state.data.map(obj => 
                    obj._id === product._id
                    ? {...obj, quantity: parseFloat(parseFloat(obj.quantity) + parseFloat(action.payload.quantity)).toFixed(2), totalPrice: parseFloat((parseFloat(obj.quantity) + parseFloat(action.payload.quantity)) * parseFloat(action.payload.price)).toFixed(2)}
                    : {...obj}),
            }
            : {
                ...state,
                data: [...state.data, {...action.payload, totalPrice: parseFloat(action.payload.totalPrice).toFixed(2)} ]
            }

            /*return {
                ...state,
                data: [...state.data, {...action.payload, quantity: action.payload.quantity} ]
            }*/
        }
        case TYPES.REMOVE_ONE_FROM_CART: {
            return {
                ...state,
                data: state.data.filter(obj => obj._id !== action.payload)
            }
        }
        case TYPES.CLEAR_CART: {
            return cartInitialState
        }
        default:
            return state
    }
}