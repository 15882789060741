import { useMemo } from "react";

export default function useColumnsCategories() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
      Header: "Subcategoría",
      accessor: "subcategory"
     },
     {
      Header: "Categoría",
      accessor: "category"
     }
   ],
   []
 );

 return columns;
}