import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    Button, Tooltip } from '@chakra-ui/react'
import { BsPrinter } from "react-icons/bs"
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFBudget from './PDFBudget'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import moment from 'moment'

const PrintPDFBudget = ({ idBudget }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleClose = () => {
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
            
        fetch("https://api.veterinariacajeao.com.ar/api/budget/" + idBudget, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                customerName: data.customer.name,
                customerStreet: data.customer.street,
                customerIva: data.customer.ivaName,
                customerCity: data.customer.city,
                customerCuit: data.customer.cuit,
                customerState: data.customer.stateName,
                budgetNumber: data.budgetNumber,
                date: moment(data.date).add(3,"h").format("L"),
                products: data.products,
                grossPrice: data.grossPrice,
                ivaPrice: data.ivaPrice,
                price: data.price,
                observations: data.observations,
                paymentMethod: data.paymentMethodName,
                validity: data.validity
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    return (
        <>
            <Tooltip label='Imprimir'><Button size="sm" colorScheme='messenger' onClick={handleOpen}><BsPrinter/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Presupuesto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFBudget {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFBudget