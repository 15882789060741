import { useMemo } from "react";

export default function useColumnsPurchases() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
      Header: "Fecha",
      accessor: "date"
     },
     {
      Header: "Fecha",
      accessor: "dateFormat"
     },
     {
       Header: "Proveedor",
       accessor: "supplier"
     },
     {
       Header: "N° de comprobante",
       accessor: "invoiceNumber"
     },
     {
       Header: "Tipo de comprobante",
       accessor: "invoiceLetter"
     },
     {
       Header: "Importe",
       accessor: "price"
     }
   ],
   []
 );

 return columns;
}