import { useMemo } from "react";

export default function useColumnsProductSales() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
      Header: "Producto",
      accessor: "name"
     }, 
     {
       Header: "Fecha",
       accessor: "date"
     }, 
     {
       Header: "Fecha No View",
       accessor: "dateNoView"
     },
     {
       Header: "Cliente",
       accessor: "customer"
     },
     {
       Header: "N° de Comp. / N° de Fact.",
       accessor: "invoiceNumber"
     },
     {
        Header: "Tipo de comprobante",
        accessor: "invoiceLetter"
     },
     {
       Header: "Cantidad",
       accessor: "quantity"
     },
     {
       Header: "Precio Unitario",
       accessor: "unitPrice"
     },
     {
       Header: "I.V.A.",
       accessor: "avgIva"
     },
     {
       Header: "Precio total",
       accessor: "totalPrice"
     }
   ],
   []
 );

 return columns;
}