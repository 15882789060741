import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,
    Input, Button, ButtonGroup, IconButton, useDisclosure, InputGroup, 
    InputLeftAddon, Stack, useToast, Select, Spacer, Flex, Tooltip } from '@chakra-ui/react'
import { BsCart4 } from "react-icons/bs"
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const SaleProductInfo = ({ _idProduct, idProduct, addToCartProduct, voucher }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, formState: { errors }, reset, setValue, getValues } = useForm()
    const toast = useToast()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/product/" + _idProduct, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("id", idProduct)
            setValue("name", data.name)
            setValue("stock", parseFloat(data.stock))
            setValue("warranty", data.warranty)
            setValue("price", parseFloat(data.price).toFixed(2))
            setValue("quantity", 1)
            setValue("discount", 0)
            setValue("avgIva", data.avgIva)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    const handleSubmit = () => {
        if (parseFloat(getValues("quantity")) < 1 || getValues("quantity") === "") {
            toast({
                title: "La cantidad debe ser mayor a 0",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        } else if (parseFloat(getValues("quantity")) > parseFloat(getValues("stock")) && voucher === "sale") {
            toast({
                title: "La cantidad ingresada es mayor al stock",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (parseFloat(getValues("price")) < 1 || getValues("price") === "") {
            toast({
                title: "El precio debe ser mayor a 0",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        const warranty = (getValues("warranty") === "" || parseInt(getValues("warranty")) === 0) ? "Sin garantía" : getValues("warranty") + " meses"

        const product = {
            _id: _idProduct,
            id: idProduct,
            name: getValues("name"),
            stock: getValues("stock"),
            serialNumber: getValues("serialNumber"),
            quantity: parseFloat(getValues("quantity")),
            price: parseFloat(getValues("price")),
            discount: parseFloat(getValues("discount")).toFixed(2) || parseFloat(0).toFixed(2),
            warranty: warranty,
            avgIva: getValues("avgIva").toFixed(2)
        }

        addToCartProduct(product)
        reset()
        onClose()
    }

    return (
        <>
            <Tooltip label='Agregar al carrito'><Button colorScheme="whatsapp" onClick={handleOpen}><BsCart4/></Button></Tooltip>
        
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <InputGroup>
                                <InputLeftAddon children='ID' />
                                <Input placeholder='ID' isDisabled required value={idProduct} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Producto' width={135} />
                                <Input placeholder='Nombre' isDisabled {...register('name')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Stock' width={135} />
                                <Input placeholder='Stock' isDisabled {...register('stock')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='N° de serie' width={135} />
                                <Input ref={initialRef} {...register('serialNumber')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Cantidad' width={135} />
                                <Input type='number' step='0.01' {...register('quantity', {min: 1})} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Precio $' width={135} />
                                <Input type='number' step='0.01' {...register('price', {required: "", min: 0})} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Descuento %' width={135} />
                                <Input placeholder="%" type='number' step='0.01' {...register('discount')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftAddon children='Garantía (meses)' width={135} />
                                <Input placeholder="En meses" type='number' {...register('warranty')} />
                            </InputGroup>
                        </Stack>

                        <Flex p={4}>
                            <Spacer/>
                            <Button colorScheme='whatsapp' mr={3} onClick={e => handleSubmit()}>Agregar</Button>
                            <Button onClick={handleClose}>Cancelar</Button>
                        </Flex>
                        
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default SaleProductInfo