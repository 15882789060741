import { Input, Button, Textarea, InputGroup, InputLeftAddon, Stack, useToast, Box, Select, Spacer, 
    Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tooltip, HStack, AlertDialog,
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, 
    useDisclosure, Flex } from '@chakra-ui/react'
import { cartInitialState, cartReducer } from '../reducers/cartReducer';
import { TYPES } from '../actions/cartAction';
import { useTable, useSortBy, usePagination } from "react-table"
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsPurchaseAdd from "../hooks/useColumnsPurchaseAdd"
import { useState, useEffect, useReducer, useRef } from "react";
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import PurchaseProductAdd from './PurchaseProductAdd';
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const PurchaseAdd = () => {
    const [stateReducer, dispatch] = useReducer(cartReducer, cartInitialState)
    const { data } = stateReducer
    const { register, formState: { errors }, reset, getValues } = useForm()
    const [purchaseDate, setPurchaseDate] = useState(new Date())
    //const [isDisabled, setIsDisabled] = useState(true)
    const [suppliers, setSuppliers] = useState([])
    const [grossPrice, setGrossPrice] = useState(parseFloat(0).toFixed(2))
    const [ivaPrice, setIvaPrice] = useState(parseFloat(0).toFixed(2))
    const [price, setPrice] = useState(parseFloat(0).toFixed(2))
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
        const arrSuppliers = data.map(supplier => {
            return { 
                id: supplier._id,
                name: supplier.name
            }
        })
        setSuppliers(arrSuppliers)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }, [])

    const columns = useColumnsPurchaseAdd();

    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
      } = table;

    const onSubmit = () => {
        if (getValues("supplier") === ""){
            toast({
                title: "Seleccione el proveedor",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (getValues("invoiceNumber") === ""){
            toast({
                title: "Ingrese el número de comprobante",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (getValues("invoiceLetter") === ""){
            toast({
                title: "Ingrese el tipo de comprobante",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (purchaseDate === null){
            toast({
                title: "Seleccione una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        onOpen()
    }

    const confirmPurchase = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const arrProductsData = []

        for (let i=0; i < data.length; i++) {
            arrProductsData.push({
                _id: data[i]._id,
                id: data[i].id,
                quantity: parseFloat(data[i].quantity),
                unitPrice: parseFloat(data[i].unitPrice),
                avgIva: parseFloat(data[i].avgIva),
                totalPrice: parseFloat(data[i].totalPrice)
            })
        }

        const purchase = {
            supplier: getValues("supplier"),
            date: moment(purchaseDate).format('L'),
            invoiceNumber: getValues("invoiceNumber"),
            invoiceLetter: getValues("invoiceLetter"),
            observations: getValues("observations") || "",
            grossPrice: parseFloat(grossPrice),
            ivaPrice: parseFloat(ivaPrice),
            price: parseFloat(price),
            products: arrProductsData,
            payments: []
        }

        fetch("https://api.veterinariacajeao.com.ar/api/purchase", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(purchase)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        setPurchaseDate(new Date())
        setGrossPrice(0)
        setIvaPrice(0)
        setPrice(0)
        dispatch({ type:TYPES.CLEAR_CART })

        toast({
            title: "La compra se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        onClose()
    }

    const handleChange = (e) => {
        /*if (e.target.value === "") {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }*/
        //console.log(getValues("letter"))
        calculatingPrices()
    }

    const addToCart = (product) => {
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
        calculatingPrices(product, true)
    }

    const removeFromCart = (product) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: product.id })
        calculatingPrices(product, false)
    }

    const calculatingPrices = (product, add) => {
        let sumGrossPrice = 0
        let sumIvaPrice = 0
        if (getValues("invoiceLetter") === "A") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
                }

                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        const ivaPriceProduct = parseFloat(grossPriceProduct) * parseFloat(product.avgIva) / 100
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                        sumIvaPrice = parseFloat(sumIvaPrice) + parseFloat(ivaPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                        sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                    }
                }
            } else {
                for (let i=0; i < data.length; i++) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
                }
            }
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
            setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))
            
        } else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            if (product) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].id === product.id) continue
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                }
                if (add === true) {
                    const productSearch = data.find(obj => obj.id === product.id)
                    if (productSearch) {
                        const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                        const grossPriceProduct = parseFloat(quantityProduct) * parseFloat(product.unitPrice)
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(grossPriceProduct)
                    } else {
                        sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    }
                }
            } else {
                for (let i=0; i < data.length; i++) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                }
            }
            
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(sumGrossPrice).toFixed(2))
        } else {
            setGrossPrice(parseFloat(0).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(0).toFixed(2))
        }
        /*let sumGrossPrice = 0
        let sumIvaPrice = 0
        if (getValues("invoiceLetter") === "A") {
            for (let i=0; i < data.length; i++) {
                sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
                sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(data[i].totalPrice) * parseFloat(data[i].avgIva) / 100)
            }
            if (product) {
                if (add === true) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) + (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                } else {
                    sumGrossPrice = parseFloat(sumGrossPrice) - parseFloat(product.totalPrice)
                    sumIvaPrice = parseFloat(sumIvaPrice) - (parseFloat(product.totalPrice) * parseFloat(product.avgIva) / 100)
                }
            }

            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(sumIvaPrice).toFixed(2))
            setPrice(parseFloat(sumGrossPrice + sumIvaPrice).toFixed(2))
        } else if (getValues("invoiceLetter") === "B" || getValues("invoiceLetter") === "C" || getValues("invoiceLetter") === "No oficial") {
            for (let i=0; i < data.length; i++) {
                sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(data[i].totalPrice)
            }
            if (product) {
                if (add === true) {
                    sumGrossPrice = parseFloat(sumGrossPrice) + parseFloat(product.totalPrice)
                } else {
                    sumGrossPrice = parseFloat(sumGrossPrice) - parseFloat(product.totalPrice)
                }
            }
            
            setGrossPrice(parseFloat(sumGrossPrice).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(sumGrossPrice).toFixed(2))
        } else {
            setGrossPrice(parseFloat(0).toFixed(2))
            setIvaPrice(parseFloat(0).toFixed(2))
            setPrice(parseFloat(0).toFixed(2))
        }*/
    }

    return(
        <>
            <Box className="content">
                <form width='500px'>
                    <Stack spacing={4}>

                        <div style={{backgroundColor: "gray",padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                            <Stack>
                                <HStack>
                                    <InputGroup width="50%">
                                        <InputLeftAddon children='Proveedor' width={140} />
                                        <Select style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} placeholder='-Selecciona-' {...register('supplier', {required: 'Por favor seleccione el proveedor'})}>
                                        {suppliers.map(supplier => {
                                            return (
                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                        )})}
                                        </Select>
                                    </InputGroup>

                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fecha' width={140} />
                                        </InputGroup>
                                        <DatePicker locale='es' selected={purchaseDate} onChange={(date) => setPurchaseDate(date)} dateFormat="dd/MM/yyyy" />
                                    </HStack>
                                </HStack>
                                {errors.supplier && <AlertPop title={errors.supplier.message} />}

                                <HStack>
                                    <InputGroup>
                                        <InputLeftAddon children='N° de Factura' width={140} />
                                        <Input style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} {...register('invoiceNumber', {required: 'Ingrese el número de comprobante'})} />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Letra' width={140} />
                                        <Select style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} placeholder='-Selecciona-' {...register('invoiceLetter', {onChange: (e) => handleChange(e), required: 'Seleccione el tipo de comprobante'})}>
                                            <option value='No oficial'>No oficial</option>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                        </Select>
                                    </InputGroup>
                                </HStack>
                                {errors.invoiceNumber && <AlertPop title={errors.invoiceNumber.message} />}
                                {errors.invoiceLetter && <AlertPop title={errors.invoiceLetter.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Observaciones' width={140} />
                                    <Textarea style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} size='sm' {...register('observations')} />
                                </InputGroup>

                            </Stack>
                        </div>

                        <div style={{padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                            <Stack>

                                <TableContainer paddingTop="15px">
                                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                        <Thead>
                                            {
                                                // Recorremos las columnas que previamente definimos
                                                headerGroups.map(headerGroup => (
                                                    // Añadimos las propiedades al conjunto de columnas
                                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        // Recorremos cada columna del conjunto para acceder a su información
                                                        headerGroup.headers.map((column) => (
                                                        // Añadimos las propiedades a cada celda de la cabecera
                                                        <Th {...column.getHeaderProps()} >
                                                            {
                                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                                            column.render("Header")
                                                            }
                                                        </Th>
                                                        ))
                                                    }
                                                    </Tr>
                                                ))
                                            }
                                        </Thead>
                                        <Tbody {...getTableBodyProps()}>
                                            {
                                                // Recorremos las filas
                                                page.map(row => {
                                                    // Llamamos a la función que prepara la fila previo renderizado
                                                    prepareRow(row);
                                                    return (
                                                    // Añadimos las propiedades a la fila
                                                    <Tr {...row.getRowProps()}>
                                                        {
                                                        // Recorremos cada celda de la fila
                                                        row.cells.map((cell) => {
                                                            // Añadimos las propiedades a cada celda de la fila
                                                            return (
                                                            <Td {...cell.getCellProps()}>
                                                                {
                                                                // Pintamos el contenido de la celda
                                                                cell.render("Cell")
                                                                }
                                                            </Td>
                                                            );
                                                        })
                                                        }
                                                        <Td>
                                                            <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                                        </Td>
                                                    </Tr>
                                                    );
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <Box paddingTop="10px" textAlign="center">
                                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                                </Box>
                            
                                <div style={{backgroundColor: "gray",padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                    <HStack>
                                        <InputGroup>
                                            <InputLeftAddon children='Importe bruto $' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled placeholder='$' type='number' step='0.01' value={grossPrice} {...register('grossPrice')} />
                                        </InputGroup>

                                        <InputGroup>
                                            <InputLeftAddon children='Importe I.V.A. $' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled placeholder='$' type='number' step='0.01' value={ivaPrice} {...register('ivaPrice')} />
                                        </InputGroup>

                                        <InputGroup>
                                            <InputLeftAddon children='Importe $' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled placeholder='$' type='number' step='0.01' value={price} {...register('price')} />
                                        </InputGroup>
                                    </HStack>
                                </div>
                        
                                <Flex p={4}>
                                    <Spacer/>
                                    <HStack>
                                        <PurchaseProductAdd addToCart={addToCart}/>
                                        <Button colorScheme='blue' mr={3} onClick={(e) => onSubmit()}>Guardar</Button>
                                    </HStack>
                                </Flex>
                                
                            </Stack>
                        </div>
                    </Stack>
                </form>

                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Confirmar compra
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Desea confirmar la compra?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme='blue' onClick={confirmPurchase} ml={3}>
                            Confirmar
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

            </Box>
        </>
    )
}

export default PurchaseAdd