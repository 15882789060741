import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    MenuItem } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFProductsStock from './PDFProductsStock'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const PrintPDFProductsMinimumStock = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")
        
        fetch("https://api.veterinariacajeao.com.ar/api/product/minimum/stock", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                products: data
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()
    }

    return (
        <>
            <MenuItem onClick={handleOpen}>Stock menor al mínimo</MenuItem>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Listado de productos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFProductsStock {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFProductsMinimumStock