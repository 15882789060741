import { useMemo } from "react";

export default function useColumnsPayments() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Fecha",
       accessor: "date"
     },
     {
       Header: "Fecha",
       accessor: "formatDate"
     },
     {
       Header: "Forma de pago",
       accessor: "paymentMethod"
     },
     {
       Header: "Importe",
       accessor: "amount"
     }
   ],
   []
 );

 return columns;
}