import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Button, 
    useDisclosure, InputGroup, InputLeftAddon, Stack, useToast, Select, Spacer, Flex, 
    Tooltip } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const SupplierEdit = ({idSupplier, handleSubmitModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const toast = useToast()
    const [states, setStates] = useState([])

    const navigate = useNavigate();

    const onSubmit = data => {
        const accessToken = localStorage.getItem("user")
        
        const supplier = {
            name: data.name,
            seller: data.seller,
            street: data.street,
            phone: data.phone,
            celPhone: data.celPhone,
            city: data.city,
            state: data.state,
            cuit: data.cuit,
            email: data.email
        }

        fetch("https://api.veterinariacajeao.com.ar/api/supplier/" + idSupplier, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(supplier)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "¡Proveedor editado!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/state", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrStates = data.map(state => {
                return { 
                    id: state._id,
                    name: state.name
                }
        })
        setStates(arrStates)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.veterinariacajeao.com.ar/api/supplier/" + idSupplier, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("name", data.name)
            setValue("seller", data.seller)
            setValue("street", data.street)
            setValue("phone", data.phone)
            setValue("celPhone", data.celPhone)
            setValue("city", data.city)
            setValue("state", data.state)
            setValue("cuit", data.cuit)
            setValue("email", data.email)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
    <>
        <Tooltip label='Editar'><Button size="sm" colorScheme='yellow' onClick={handleOpen}><EditIcon/></Button></Tooltip>

        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={handleClose}
            size='lg'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Editar proveedor</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Nombre' width={127} />
                            <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del cliente'})} />
                        </InputGroup>
                        {errors.name && <AlertPop title={errors.name.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Vendedor' width={127} />
                            <Input placeholder='Vendedor' {...register('seller')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Dirección' width={127} />
                            <Input placeholder='Dirección' {...register('street')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Ciudad' width={127} />
                            <Input placeholder='Ciudad' {...register('city')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Provincia' width={127} />
                            <Select placeholder='-Selecciona-' {...register('state')}>
                            {states.map(state => {
                                return (
                                <option key={state.id} value={state.id}>{state.name}</option>
                            )})}
                            </Select>
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Teléfono' width={127} />
                            <Input placeholder='Teléfono' {...register('phone')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Celular' width={127} />
                            <Input placeholder='Celular' {...register('celPhone')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='C.U.I.T.' width={127} />
                            <Input placeholder='Solo números' type='number' {...register('cuit', {min: 0})} />
                        </InputGroup>
                        {errors.cuit && <AlertPop title={"El número debe ser mayor a 0"} />}

                        <InputGroup>
                            <InputLeftAddon children='E-mail' width={127} />
                            <Input placeholder='E-mail' type='email' {...register('email')} />
                        </InputGroup>

                    </Stack>
                    <Flex p={4}>
                    <Spacer/>
                    <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                    <Button onClick={handleClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

            </ModalContent>
        </Modal>
    </>
    )
}

export default SupplierEdit