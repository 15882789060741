import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Button, 
    useDisclosure, InputGroup, InputLeftAddon, Stack, useToast, Spacer, Flex, Menu, MenuItem, MenuButton, 
    MenuList, Select } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const ProductUpdatePrice = ({data, handleSubmitModal}) => {
    const { isOpen: isOpenModalPrice, onOpen: onOpenModalPrice, onClose: onCloseModalPrice } = useDisclosure()
    const { isOpen: isOpenModalPercentage, onOpen: onOpenModalPercentage, onClose: onCloseModalPercentage } = useDisclosure()
    const { isOpen: isOpenModalSupplier, onOpen: onOpenModalSupplier, onClose: onCloseModalSupplier } = useDisclosure()
    const initialRef = useRef(null)
    const { register: registerModalPrice, handleSubmit: handleSubmitModalPrice, formState: { errors: errorsModalPrice }, reset: resetModalPrice } = useForm()
    const { register: registerModalPercentage, handleSubmit: handleSubmitModalPercentage, formState: { errors: errorsModalPercentage }, reset: resetModalPercentage } = useForm()
    const { register: registerModalSupplier, handleSubmit: handleSubmitModalSupplier, formState: { errors: errorsModalSupplier }, reset: resetModalSupplier } = useForm()
    const [suppliers, setSuppliers] = useState([])
    const toast = useToast()

    const navigate = useNavigate();

    const onSubmitModalPrice = (priceUpdate) => {
        const accessToken = localStorage.getItem("user")

        const arrProducts = []
        
        for (let i = 0; i < data.length; i++) {
            arrProducts.push({
                id: data[i]._id,
                price: parseFloat(priceUpdate.price)
            })
        }
        
        fetch("https://api.veterinariacajeao.com.ar/api/product/update/updatePrice", {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(arrProducts)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        toast({
            title: "¡Precios actualizados!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onCloseModalPrice()

    }

    const handleOpenModalPrice = () => {
        resetModalPrice()
        onOpenModalPrice()
    }

    const handleCloseModalPrice = () => {
        resetModalPrice()
        onCloseModalPrice()
    }

    const onSubmitModalPercentage = (percentageUpdate) => {
        const accessToken = localStorage.getItem("user")

        const arrProducts = []

        for (let i = 0; i < data.length; i++) {
            arrProducts.push({
                id: data[i]._id,
                percentage: parseFloat(percentageUpdate.percentage)
            })
        }

        fetch("https://api.veterinariacajeao.com.ar/api/product/update/updatePercentage", {
            method: "PUT",
            headers: {'Content-Type': 'application/json',
            "x-auth-token": accessToken
        },
            body: JSON.stringify(arrProducts)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        toast({
            title: "¡Precios actualizados!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onCloseModalPercentage()

    }

    const handleOpenModalPercentage = () => {
        resetModalPercentage()
        onOpenModalPercentage()
    }

    const handleCloseModalPercentage = () => {
        resetModalPercentage()
        onCloseModalPercentage()
    }

    const onSubmitModalSupplier = (infoSubmit) => {
        const accessToken = localStorage.getItem("user")
        
        const info = {
            percentage: parseFloat(infoSubmit.percentage),
            supplier: infoSubmit.supplier
        }

        fetch("https://api.veterinariacajeao.com.ar/api/product/update/updateSupplier", {
            method: "PUT",
            headers: {'Content-Type': 'application/json',
            "x-auth-token": accessToken
        },
            body: JSON.stringify(info)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        toast({
            title: "¡Precios actualizados!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onCloseModalSupplier()
    }

    const handleOpenModalSupplier = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
        const arrSuppliers = data.map(supplier => {
            return { 
                id: supplier._id,
                name: supplier.name
            }
        })
        setSuppliers(arrSuppliers)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        resetModalSupplier()
        onOpenModalSupplier()
    }

    const handleCloseModalSupplier = () => {
        resetModalSupplier()
        onCloseModalSupplier()
    }

    return (
        <>
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size='sm' colorScheme='cyan' color='white'>
                    Actualizar precios
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={handleOpenModalPrice}>Por valor</MenuItem>
                    <MenuItem onClick={handleOpenModalPercentage}>Por porcentaje</MenuItem>
                    <MenuItem onClick={handleOpenModalSupplier}>Por proveedor</MenuItem>
                </MenuList>
            </Menu>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpenModalPrice}
                onClose={handleCloseModalPrice}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Actualizar precios</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmitModalPrice(onSubmitModalPrice)}>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Valor $' width={127} />
                            <Input ref={initialRef} placeholder='$' type='number' step='0.01' {...registerModalPrice('price', {required: 'Por favor ingrese el valor'})} />
                        </InputGroup>
                        {errorsModalPrice.price && <AlertPop title={"Campo requerido"} />}

                    </Stack>
                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Aceptar</Button>
                        <Button onClick={handleCloseModalPrice}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpenModalPercentage}
                onClose={handleCloseModalPercentage}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Actualizar precios</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmitModalPercentage(onSubmitModalPercentage)}>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Porcentaje %' width={127} />
                            <Input ref={initialRef} placeholder='%' type='number' step='0.01' {...registerModalPercentage('percentage', {required: 'Por favor ingrese el valor'})} />
                        </InputGroup>
                        {errorsModalPercentage.percentage && <AlertPop title={"Campo requerido"} />}

                    </Stack>
                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Aceptar</Button>
                        <Button onClick={handleCloseModalPercentage}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpenModalSupplier}
                onClose={handleCloseModalSupplier}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Actualizar precios</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmitModalSupplier(onSubmitModalSupplier)}>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Porcentaje %' width={127} />
                            <Input ref={initialRef} placeholder='%' type='number' step='0.01' {...registerModalSupplier('percentage', {required: 'Por favor ingrese el valor'})} />
                        </InputGroup>
                        {errorsModalPercentage.percentage && <AlertPop title={"Campo requerido"} />}

                        <InputGroup>
                            <InputLeftAddon children='Proveedor' width={127} />
                            <Select placeholder='-Selecciona-' {...registerModalSupplier('supplier', {required: 'Por favor seleccione el proveedor'})}>
                            {suppliers.map(supplier => {
                                return (
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )})}
                            </Select>
                        </InputGroup>
                        {errorsModalSupplier.supplier && <AlertPop title={errorsModalSupplier.supplier.message} />}

                    </Stack>
                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Aceptar</Button>
                        <Button onClick={handleCloseModalSupplier}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )

}

export default ProductUpdatePrice