import { Box, Stack, HStack, InputGroup, InputLeftAddon, useDisclosure, useToast, Input, Textarea, 
    Spacer, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tooltip, Modal, ModalOverlay, 
    ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, AlertDialog, AlertDialogBody, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react'
import { BsFileDiffFill } from "react-icons/bs"
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import authService from "../services/authService"
import { useNavigate } from "react-router-dom";
import AlertPop from './AlertPop'
import { useTable, useSortBy, usePagination } from "react-table"
import useColumnsSaleAdd from "../hooks/useColumnsSaleAdd"
const moment = require('moment')
moment.locale('es')

const Rebill = ({ idVoucher, voucherLetter, isInvoice, handleSubmitModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenConfirmRebill, onOpen: onOpenConfirmRebill, onClose: onCloseConfirmRebill } = useDisclosure()
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors }, reset, setValue } = useForm()
    const [data, setData] = useState([])
    const toast = useToast()
    const initialRef = useRef(null)
    const cancelRefConfirmRebill = useRef()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        if (isInvoice === true) {
            toast({
                title: "Este comprobante ya se encuentra facturado y no se puede recotizar",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        if (voucherLetter != "No oficial") {
            toast({
                title: "Este comprobante no se puede recotizar",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        setLoading(true)
        reset()

        fetch("https://api.veterinariacajeao.com.ar/api/sale/" + idVoucher, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("invoiceNumber", data.invoiceNumber)
            setValue("invoiceDate", moment(data.date).format("L"))
            setValue("customer", data.customer.name)
            setValue("iva", data.customer.ivaName)
            setValue("cuit", data.customer.cuit)
            setValue("street", data.customer.street)
            setValue("city", data.customer.city)
            setValue("observations", data.observations)
            setValue("totalPrice", data.price)
            
            const arrProducts = data.products.map(product => {
                return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    serialNumber: product.serialNumber,
                    quantity: parseFloat(product.quantity).toFixed(2),
                    warranty: product.warranty,
                    discount: parseFloat(product.discount).toFixed(2),
                    unitPrice: parseFloat(product.unitPrice).toFixed(2),
                    avgIva: parseFloat(product.avgIva).toFixed(2),
                    totalPrice: parseFloat(product.totalPrice).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const columns = useColumnsSaleAdd();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow
    } = table

    const handleClose = () => {
        onClose()
    }

    const handleRebillProduct = (idProduct) => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/sale/rebillProduct/" + idVoucher + "/" + idProduct, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            setValue("totalPrice", data.price)

            const arrProducts = data.products.map(product => {
                return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    serialNumber: product.serialNumber,
                    quantity: parseFloat(product.quantity).toFixed(2),
                    warranty: product.warranty,
                    discount: parseFloat(product.discount).toFixed(2),
                    unitPrice: parseFloat(product.unitPrice).toFixed(2),
                    avgIva: parseFloat(product.avgIva).toFixed(2),
                    totalPrice: parseFloat(product.totalPrice).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const rebillAll = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/sale/rebillAll/" + idVoucher, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            setValue("totalPrice", data.price)

            const arrProducts = data.products.map(product => {
                return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    serialNumber: product.serialNumber,
                    quantity: parseFloat(product.quantity).toFixed(2),
                    warranty: product.warranty,
                    discount: parseFloat(product.discount).toFixed(2),
                    unitPrice: parseFloat(product.unitPrice).toFixed(2),
                    avgIva: parseFloat(product.avgIva).toFixed(2),
                    totalPrice: parseFloat(product.totalPrice).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const onSubmit = () => {
        onOpenConfirmRebill()
    }

    const confirmRebill = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/sale/saveRebill/" + idVoucher, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(data)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        handleSubmitModal()
        onClose()
    }

    return (
        <>
            <Tooltip label='Recotizar'><Button size="sm" colorScheme="blackAlpha" onClick={handleOpen}><BsFileDiffFill/></Button></Tooltip>
        
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='6xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Comprobante</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {!loading && <Stack spacing={4}>
                            {/*<Card align="center" variant="elevated" padding="10px">*/}
                            <div style={{backgroundColor: "gray",padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                <Stack spacing={4}>
                                    <HStack>
                                        <InputGroup>
                                            <InputLeftAddon children='N° Comprobante' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('invoiceNumber')} />
                                        </InputGroup>

                                        <HStack spacing={1}>
                                            <InputGroup>
                                                <InputLeftAddon children='Fecha' width={140} />
                                            </InputGroup>
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('invoiceDate')} />
                                        </HStack>
                                    </HStack>

                                    <HStack>
                                        <InputGroup>
                                            <InputLeftAddon children='Cliente' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('customer', {required: 'Seleccione el cliente'})} />
                                        </InputGroup>

                                        <InputGroup>
                                            <InputLeftAddon children='Condición I.V.A.' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('iva')} />
                                        </InputGroup>
                                    </HStack>
                                    {errors.customer && <AlertPop title={errors.customer.message} />}

                                    <HStack>
                                        <InputGroup>
                                            <InputLeftAddon children='C.U.I.T.' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('cuit')} />
                                        </InputGroup>

                                        <InputGroup>
                                            <InputLeftAddon children='Dirección' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('street')} />
                                        </InputGroup>

                                        <InputGroup>
                                            <InputLeftAddon children='Ciudad' width={140} />
                                            <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('city')} />
                                        </InputGroup>
                                    </HStack>

                                    <InputGroup>
                                        <InputLeftAddon children='Observaciones' width={140} />
                                        <Textarea style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} size='sm' isDisabled {...register('observations')} />
                                    </InputGroup>
                                    {errors.invoiceLetter && <AlertPop title={errors.invoiceLetter.message} />}
                            
                                </Stack>
                            </div>
                            {/*</Card>*/}
                            <div style={{padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                <Stack spacing={4}>
                                    <TableContainer paddingTop="15px">
                                        <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                            <Thead>
                                                {
                                                    // Recorremos las columnas que previamente definimos
                                                    headerGroups.map(headerGroup => (
                                                        // Añadimos las propiedades al conjunto de columnas
                                                        <Tr {...headerGroup.getHeaderGroupProps()}>
                                                        {
                                                            // Recorremos cada columna del conjunto para acceder a su información
                                                            headerGroup.headers.map((column) => (
                                                            // Añadimos las propiedades a cada celda de la cabecera
                                                            <Th {...column.getHeaderProps()} >
                                                                {
                                                                // Pintamos el título de nuestra columna (propiedad "Header")
                                                                column.render("Header")
                                                                }
                                                            </Th>
                                                            ))
                                                        }
                                                        </Tr>
                                                    ))
                                                }
                                            </Thead>
                                            <Tbody {...getTableBodyProps()}>
                                                {
                                                    // Recorremos las filas
                                                    page.map(row => {
                                                        // Llamamos a la función que prepara la fila previo renderizado
                                                        prepareRow(row);
                                                        return (
                                                        // Añadimos las propiedades a la fila
                                                        <Tr {...row.getRowProps()}>
                                                            {
                                                            // Recorremos cada celda de la fila
                                                            row.cells.map((cell) => {
                                                                // Añadimos las propiedades a cada celda de la fila
                                                                return (
                                                                <Td {...cell.getCellProps()}>
                                                                    {
                                                                    // Pintamos el contenido de la celda
                                                                    cell.render("Cell")
                                                                    }
                                                                </Td>
                                                                );
                                                            })
                                                            }
                                                            <Td>
                                                                <Button size="sm" colorScheme="yellow" onClick={() => handleRebillProduct(row.values._id)}>Recotizar</Button>
                                                            </Td>
                                                        </Tr>
                                                        );
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>

                                    <Box paddingTop="10px" textAlign="center">
                                        <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                        <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                                    </Box>

                                    <div style={{backgroundColor: "gray",padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                        <HStack>
                                            <Spacer/>                              
                                            <InputGroup width={300} paddingLeft="10px">
                                                <InputLeftAddon children='Importe $' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('totalPrice')} width={140} textAlign="right" />
                                            </InputGroup>
                                        </HStack>
                                    </div>
                                    
                                    <Flex p={4}>
                                        <Spacer/>
                                        <HStack>
                                            <Button colorScheme='yellow' mr={3} onClick={() => rebillAll()}>Recotizar todo</Button>
                                            <Button colorScheme='blue' mr={3} onClick={() => onSubmit()}>Guardar</Button>
                                        </HStack>
                                    </Flex>
                                </Stack>
                            </div>
                        </Stack>}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenConfirmRebill}
                leastDestructiveRef={cancelRefConfirmRebill}
                onClose={onCloseConfirmRebill}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar recotización
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la recotización?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefConfirmRebill} onClick={onCloseConfirmRebill}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmRebill} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default Rebill