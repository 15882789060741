import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    MenuItem, Button, Tooltip } from '@chakra-ui/react'
import { BsPrinter } from "react-icons/bs"
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFPurchasePlan from './PDFPurchasePlan'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const PrintPDFPurchasePlan = ({ idPurchasePlan }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")
        
        fetch("https://api.veterinariacajeao.com.ar/api/purchasePlan/" + idPurchasePlan, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                products: data
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()
    }

    return (
        <>
            <Tooltip label='Imprimir'><Button size="sm" colorScheme="linkedin" onClick={handleOpen}><BsPrinter/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Listado de productos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFPurchasePlan {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFPurchasePlan