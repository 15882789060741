import { useMemo } from "react";

export default function useColumnsBudgets() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "N° de Presupuesto",
       accessor: "budgetNumber"
     },
     {
       Header: "Cliente",
       accessor: "customer"
     },
     {
       Header: "Importe",
       accessor: "price"
     },
     {
       Header: "Fecha",
       accessor: "date"
     },
     {
       Header: "Date No View",
       accessor: "dateNoView"
     }
   ],
   []
 );

 return columns;
}