import { Box, Stack, HStack, InputGroup, InputLeftAddon, Select, AlertDialog, AlertDialogBody, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure,
    useToast, Input, Textarea, Spacer, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Tooltip, CircularProgress, Card, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, ButtonGroup, IconButton, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { PDFViewer } from '@react-pdf/renderer'
import { useTable, usePagination } from "react-table"
import { TYPES } from '../actions/despatchAction'
import { despatchInitialState, despatchReducer } from '../reducers/despatchReducer'
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsDespatch from "../hooks/useColumnsDespatch"
import { useState, useReducer, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import CustomerInvoiceSearch from './CustomerInvoiceSearch'
import DespatchEditProduct from './DespatchEditProduct'
import PDFDespatch from './PDFDespatch'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const DespatchAdd = () => {
    const [stateReducer, dispatch] = useReducer(despatchReducer, despatchInitialState)
    const { data } = stateReducer
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm()
    const [despatchDate, setDespatchDate] = useState(new Date())
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const cancelRefAlert = useRef()
    const { isOpen: isOpenDespatch, onOpen: onOpenDespatch, onClose: onCloseDespatch } = useDisclosure()
    const toast = useToast()
    const [originalData, setOriginalData] = useState([])
    const [loading, setLoading] = useState(false)
    const [idSale, setIdSale] = useState(0)
    const [customer, setCustomer] = useState({})
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/despatch/get/despatch/number", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const despatch = data.map(despatch => {
                return { 
                    despatchNumber: despatch.despatchNumber
                }
            })
            setValue("despatchNumber", despatch[0].despatchNumber)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        setDespatchDate(new Date())
        setOriginalData([])
        setIdSale(0)
        setCustomer({})
        dispatch({ type:TYPES.CLEAR_DESPATCH })
        onOpen()
    }
    
    const columns = useColumnsDespatch();

    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"]} }, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
      } = table;

    const onSubmit = () => {
        const arrProducts = []

        if (data.length < 1) {
            toast({
                title: "No hay productos para generar el remito",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        for (let i=0; i < data.length; i++) {
            const product = originalData.find(obj => obj._id === data[i]._id)

            if (parseFloat(data[i].quantity) > parseFloat(product.quantity)) {
                toast({
                    title: `El producto ` + product.name + ` está superando la cantidad facturada`,
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
                return
            }

            arrProducts.push({
                _id: product._id,
                quantity: data[i].quantity
            })
        }

        onOpenAlert()
    }

    const confirmDespatch = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const arrProducts = []

        for (let i=0; i < data.length; i++) {
            const product = originalData.find(obj => obj._id === data[i]._id)

            arrProducts.push({
                _id: product._id,
                quantity: parseFloat(data[i].quantity)
            })
        }
        
        const despatch = {
            sale: idSale,
            despatchNumber: getValues("despatchNumber"),
            observations: getValues("observations"),
            date: moment(despatchDate).format('L'),
            products: arrProducts
        }

        fetch("https://api.veterinariacajeao.com.ar/api/despatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(despatch)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        //reset()
        //setDespatchDate(new Date())
        //dispatch({ type:TYPES.CLEAR_DESPATCH })

        openDespatch(despatch)
    }

    const openDespatch = (despatch) => {
        moment.locale("es")

        const arrProducts = []

        for (let i=0; i < data.length; i++) {
            const product = originalData.find(obj => obj._id === data[i]._id)

            arrProducts.push({
                _id: product._id,
                quantity: parseFloat(data[i].quantity),
                id: parseFloat(data[i].id),
                name: data[i].name
            })
        }

        setForm(form = {
            customerName: customer.name,
            customerStreet: customer.street,
            customerIva: customer.ivaName,
            customerCity: customer.city,
            customerState: customer.stateName,
            customerCuit: customer.cuit,
            despatchNumber: despatch.despatchNumber,
            invoiceNumber: getValues("invoiceNumber"),
            date: moment(despatchDate).format("L"),
            products: arrProducts,
            observations: despatch.observations
        })

        onOpenDespatch()

        toast({
            title: "El remito se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        onCloseAlert()
        onClose()
    }

    const handleSelect = (idCustomer, idNewSale) => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)
        dispatch({ type:TYPES.CLEAR_DESPATCH })

        fetch("https://api.veterinariacajeao.com.ar/api/customer/" + idCustomer, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("customer", data.name)
            setValue("iva", data.ivaName)
            setValue("cuit", data.cuit)
            setValue("street", data.street)
            setValue("city", data.city)
            setCustomer(data)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.veterinariacajeao.com.ar/api/sale/saleAndDespatchs/" + idNewSale, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setIdSale(idNewSale)
            setValue("invoiceNumber", data.invoiceNumber)
            const arrProducts = data.products.map(product => {
                return {
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    serialNumber: product.serialNumber,
                    quantity: parseFloat(product.quantity).toFixed(2)
                }
            })
            for (let i=0; i < arrProducts.length; i++) {
                dispatch({ type:TYPES.ADD_TO_DESPATCH, payload: arrProducts[i] })
            }
            setOriginalData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    const handleEditQuantity = (product) => {
        dispatch({ type:TYPES.ADD_TO_DESPATCH, payload: product })
        /*let arrProducts = data
        const productIndex = arrProducts.findIndex(obj => obj.id === idProduct)

        arrProducts[productIndex] = {
            ...arrProducts[productIndex],
            quantity: quantity
        }

        console.log(arrProducts)

        setData(arrProducts)*/
    }

    const removeFromCart = (idProduct) => {
        dispatch({ type:TYPES.REMOVE_TO_DESPATCH, payload: idProduct })
    }

    return (
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="blackAlpha" onClick={handleOpen}>Remito</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="blackAlpha" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='6xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nuevo remito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(onSubmit)} width='500px'>
                            <Stack spacing={4}>
                                {/*<Card align="center" variant="elevated" padding="10px">*/}
                                <div style={{backgroundColor: "gray", padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                    <Stack spacing={4}>
                                        <HStack>
                                            <InputGroup>
                                                <InputLeftAddon children='N° de Remito' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('despatchNumber')} />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='N° Comprobante' width={150} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('invoiceNumber')} />
                                            </InputGroup>

                                            <HStack spacing={1}>
                                                <InputGroup>
                                                    <InputLeftAddon children='Fecha' width={140} />
                                                </InputGroup>
                                                <DatePicker locale='es' selected={despatchDate} onChange={(date) => setDespatchDate(date)} dateFormat="dd/MM/yyyy" />
                                            </HStack>
                                        </HStack>

                                        <HStack>
                                            <InputGroup>
                                                <InputLeftAddon children='Cliente' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('customer', {required: 'Seleccione el cliente'})} />
                                                <CustomerInvoiceSearch handleSelect={handleSelect}/>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Condición I.V.A.' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('iva')} />
                                            </InputGroup>
                                        </HStack>
                                        {errors.customer && <AlertPop title={errors.customer.message} />}

                                        <HStack>
                                            <InputGroup>
                                                <InputLeftAddon children='C.U.I.T.' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('cuit')} />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Dirección' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('street')} />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLeftAddon children='Ciudad' width={140} />
                                                <Input style={{backgroundColor: "white", color:"black", fontWeight: "bold"}} isDisabled {...register('city')} />
                                            </InputGroup>
                                        </HStack>

                                        <InputGroup>
                                            <InputLeftAddon children='Observaciones' width={140} />
                                            <Textarea style={{backgroundColor: "#b3b3b3", color:"black", fontWeight: "bold"}} size='sm' {...register('observations')} />
                                        </InputGroup>
                                        {errors.invoiceLetter && <AlertPop title={errors.invoiceLetter.message} />}
                                    </Stack>
                                </div>
                                {/*</Card>*/}
                        
                                <div style={{padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                                    {loading && <Box paddingTop="15px" textAlign='center'> 
                                        <CircularProgress isIndeterminate color='green.300' />
                                    </Box>}

                                    {!loading && <TableContainer paddingTop="15px">
                                        <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                            <Thead>
                                                {
                                                    // Recorremos las columnas que previamente definimos
                                                    headerGroups.map(headerGroup => (
                                                        // Añadimos las propiedades al conjunto de columnas
                                                        <Tr {...headerGroup.getHeaderGroupProps()}>
                                                        {
                                                            // Recorremos cada columna del conjunto para acceder a su información
                                                            headerGroup.headers.map((column) => (
                                                            // Añadimos las propiedades a cada celda de la cabecera
                                                            <Th {...column.getHeaderProps()} >
                                                                {
                                                                // Pintamos el título de nuestra columna (propiedad "Header")
                                                                column.render("Header")
                                                                }
                                                            </Th>
                                                            ))
                                                        }
                                                        </Tr>
                                                    ))
                                                }
                                            </Thead>
                                            <Tbody {...getTableBodyProps()}>
                                                {
                                                    // Recorremos las filas
                                                    page.map(row => {
                                                        // Llamamos a la función que prepara la fila previo renderizado
                                                        prepareRow(row);
                                                        return (
                                                        // Añadimos las propiedades a la fila
                                                        <Tr {...row.getRowProps()}>
                                                            {
                                                            // Recorremos cada celda de la fila
                                                            row.cells.map((cell) => {
                                                                // Añadimos las propiedades a cada celda de la fila
                                                                return (
                                                                <Td {...cell.getCellProps()}>
                                                                    {
                                                                    // Pintamos el contenido de la celda
                                                                    cell.render("Cell")
                                                                    }
                                                                </Td>
                                                                );
                                                            })
                                                            }
                                                            <Td>
                                                                <HStack>
                                                                    <DespatchEditProduct product={row.values} handleEditQuantity={handleEditQuantity} />
                                                                    <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values._id)}><DeleteIcon/></Button></Tooltip>
                                                                </HStack>
                                                            </Td>
                                                        </Tr>
                                                        );
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}

                                    <Flex p={4}>
                                        <Spacer/>
                                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                                    </Flex>
                                </div>
                            </Stack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar remito
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar el remito?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmDespatch} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal
                isOpen={isOpenDespatch}
                onClose={onCloseDespatch}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Remito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFDespatch {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default DespatchAdd