import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Input, Table,
    Thead, Tbody, Tr, Th, Td, TableContainer, useDisclosure, Stack, Box, Tooltip, HStack, CircularProgress,
    useToast } from '@chakra-ui/react'
import ProductAdd from './ProductAdd'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table"
import { BsCart4 } from "react-icons/bs"
import useColumnsPurchasePlanAdd from "../hooks/useColumnsPurchasePlanAdd";
import { GlobalFilter } from './GlobalFilter'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const PurchasePlanProductAdd = ({ addToCart }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(false)
    const toast = useToast()

    const navigate = useNavigate();

    const columns = useColumnsPurchasePlanAdd()
    const table = useTable({ columns, data, initialState: {pageSize: 10, pageIndex: 0, hiddenColumns: ["_id", "minimum", "maximum", "quantity", "totalPrice"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
      } = table;

      const { globalFilter } = state

      const addProduct = (row) => {
        
        if (quantity <= 0) {
            toast({
                title: "La cantidad tiene que ser mayor a 0",
                status: "error",
                duration: 5000,
                isClosable: true
            });
            return
        }

        const product = {
            _id: row.values._id,
            id: row.values.id,
            name: row.values.name,
            stock: parseFloat(row.values.stock),
            minimum: parseFloat(row.values.minimum),
            maximum: parseFloat(row.values.maximum),
            quantity: parseFloat(quantity),
            price: parseFloat(row.values.price),
            totalPrice: parseFloat(parseFloat(quantity) * parseFloat(row.values.price))
        }

        addToCart(product)

        setQuantity(0)

        handleOpen()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/product/getProductsActives", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrProducts = data.map(product => {
            return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    stock: product.stock,
                    minimum: product.minimumStock,
                    maximum: product.maximumStock,
                    price: parseFloat(product.purchasePrice).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const handleClose = () => {
        onClose()
    }

    const onChangeQuantity = (e) => {
        setQuantity(e.target.value)
    }

    const handleSubmitModal = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/product/getProductsActives", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrProducts = data.map(product => {
            return { 
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    stock: product.stock,
                    minimum: product.minimumStock,
                    maximum: product.maximumStock,
                    price: parseFloat(product.price).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }

    return (
        <>
            <Button colorScheme='whatsapp' width={150} onClick={handleOpen}>Agregar productos</Button>

            <Modal
                isOpen={isOpen}
                onClose={handleClose}
                size='4xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cargar producto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <ProductAdd handleSubmitModal={handleSubmitModal}/>

                            <Box paddingTop="10px">
                                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                            </Box>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <HStack>
                                                            <Input placeholder="Cantidad" width={100} backgroundColor="white" type='number' step='0.01' textAlign="center" onChange={(e) => onChangeQuantity(e)}/>
                                                            <Tooltip label='Añadir al carrito'><Button size="sm" colorScheme="green" onClick={() => addProduct(row)}><BsCart4/></Button></Tooltip>
                                                        </HStack>
                                                    </Td>
                                                    {/*<Td>
                                                        <Input placeholder="Precio $" width={100} backgroundColor="white" type='number' step='0.01' textAlign="center"/>
                                                    </Td>
                                                    <Td>
                                                        <Tooltip label='Añadir al carrito'><Button size="sm" colorScheme="green" onClick={() => addProduct(row.values)}><BsCart4/></Button></Tooltip>
                                                </Td>*/}
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>
                        </Stack>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )

}

export default PurchasePlanProductAdd