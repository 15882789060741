import { Box, Button, Flex, Spacer, Divider, FormControl, FormLabel, Switch, Table, 
    Thead, Tbody, Tr, Th, Td, TableContainer, Select, HStack, Tooltip, CircularProgress, VStack } from '@chakra-ui/react'
import 'moment/locale/es'
import { useTable, useSortBy, usePagination } from "react-table"
import useColumnsSupplierCurrentAccount from "../hooks/useColumnsSupplierCurrentAccount"
import Purchase from './Purchase'
import { useEffect, useState } from 'react'
import PurchasePayments from './PurchasePayments';
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const SupplierCurrentAccount = () => {
    const [suppliers, setSuppliers] = useState([])
    const [supplier, setSupplier] = useState("")
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'month')))
    const [toDate, setToDate] = useState(new Date())
    const [completeAccount, setCompleteAccount] = useState(false)
    const [unpaid, setUnpaid] = useState(false)
    const [totalPurchase, setTotalPurchase] = useState("")
    const [totalPaid, setTotalPaid] = useState("")
    const [balance, setBalance] = useState("")
    const [loading, setLoading] = useState(false)
    const [submitModal, setSubmitModal] = useState(false)
    const [data, setData] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSuppliers = data.map(supplier => {
                return { 
                    id: supplier._id,
                    name: supplier.name
                }
            })
            setSuppliers(arrSuppliers)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')

        const filter = {
            supplier: supplier || null,
            //fromDate: moment(new Date(fromDate)).format('DD-MM-YYYY'),
            //toDate: moment(new Date(toDate)).format('DD-MM-YYYY'),
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-"),
            completeAccount: completeAccount,
            unpaid: unpaid
        }

        fetch("https://api.veterinariacajeao.com.ar/api/purchase/supplier/" + filter.supplier + "/fromDate/" + 
        filter.fromDate + "/toDate/" + filter.toDate + "/completeAccount/" + filter.completeAccount + 
        "/unpaid/" + filter.unpaid, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrPurchases = data.map(purchase => {
                return {
                    id: purchase._id,
                    date: purchase.date,
                    formatDate: moment(purchase.date).add(3,"h").format('L'),
                    supplier: purchase.supplier,
                    invoiceNumber: purchase.invoiceNumber,
                    invoiceLetter: purchase.invoiceLetter,
                    price: parseFloat(purchase.price).toFixed(2),
                    totalPaid: parseFloat(purchase.totalPaid).toFixed(2),
                    balance: parseFloat(purchase.balance).toFixed(2)
                }
            })
            let sumPrice = 0
            let sumTotalPaid = 0
            let sumBalance = 0

            for (let i=0; i < arrPurchases.length; i++) {
                sumPrice = parseFloat(sumPrice) + parseFloat(arrPurchases[i].price)
                sumTotalPaid = parseFloat(sumTotalPaid) + parseFloat(arrPurchases[i].totalPaid)
                sumBalance = parseFloat(sumBalance) + parseFloat(arrPurchases[i].balance)
            }

            setTotalPurchase(parseFloat(sumPrice).toFixed(2))
            setTotalPaid(parseFloat(sumTotalPaid).toFixed(2))
            setBalance(parseFloat(sumBalance).toFixed(2))
            setData(arrPurchases)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })    

    }, [supplier, fromDate, toDate, completeAccount, unpaid, submitModal])

    const columns = useColumnsSupplierCurrentAccount();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "date", "isInvoice"], sortBy: [{id: "date", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const handleFromDate = (date) => {
        setFromDate(date || new Date())
    }

    const handleToDate = (date) => {
        setToDate(date || new Date())
    }

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }


    return (
        <>
            <Box className="content">

                <Flex paddingTop="10px">
                    <Box>
                        <HStack>
                            <FormLabel>Proveedor:</FormLabel>
                            <Select placeholder='-Selecciona-' onChange={(e) => setSupplier(e.target.value)}>
                            {suppliers.map(supplier => {
                                return (
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )})}
                            </Select>

                            <FormLabel>Desde:</FormLabel>
                            <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                            <FormLabel>Hasta:</FormLabel>
                            <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                        </HStack>
                    </Box>

                    <Spacer/>

                </Flex>

                <Box paddingTop="10px">
                    <HStack>

                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='completeAccount' mb='0'>Cuenta completa</FormLabel>
                        <Switch id='completeAccount' defaultChecked={completeAccount} onChange={(e) => setCompleteAccount(!completeAccount)} />
                    </FormControl>

                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='unpaid' mb='0'>Ver sólo impagas</FormLabel>
                        <Switch id='unpaid' defaultChecked={unpaid} onChange={(e) => setUnpaid(!unpaid)} />
                    </FormControl>

                    </HStack>
                </Box>

                <Divider paddingTop="15px"/>

                <Flex paddingTop="10px">
                    <Box>
                        <FormLabel textAlign="right">Total de compras: </FormLabel>
                        <FormLabel textAlign="right">Total pagado: </FormLabel>
                        <FormLabel textAlign="right">Saldo: </FormLabel>
                    </Box>
                    <Box>
                        <FormLabel textAlign="left">${totalPurchase}</FormLabel>
                        <FormLabel textAlign="left">${totalPaid}</FormLabel>
                        <FormLabel textAlign="left" bg="red">${balance}</FormLabel>
                    </Box>
                </Flex>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <HStack>
                                                <Purchase idPurchase={row.values.id} handleSubmitModal={handleSubmitModal}/>
                                                <PurchasePayments idPurchase={row.values.id} price={row.values.price} handleSubmitModal={handleSubmitModal}/>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}

            </Box>
        </>
    )
}

export default SupplierCurrentAccount