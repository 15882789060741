import { useMemo } from "react";

export default function useColumnsDespatch() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Producto",
       accessor: "name"
     },
     {
       Header: "N° de serie",
       accessor: "serialNumber"
     },
     {
       Header: "Cantidad",
       accessor: "quantity"
     }
   ],
   []
 );

 return columns;
}