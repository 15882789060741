import {useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import authService from "./services/authService"
import Products from './components/Products'
import Customers from './components/Customers'
import Suppliers from './components/Suppliers'
import PurchaseAdd from './components/PurchaseAdd'
import Purchases from './components/Purchases'
import SupplierCurrentAccount from './components/SupplierCurrentAccount'
import PurchasePlan from './components/PurchasePlan'
import SaleAdd from './components/SaleAdd'
import DespatchAdd from './components/DespatchAdd'
import Budgets from './components/Budgets'
import BudgetAdd from './components/BudgetAdd'
import Sales from './components/Sales'
import Despatchs from './components/Despatchs'
import Categories from './components/Categories'
import Subcategories from './components/Subcategories'
import Login from './components/Login'
import Logout from './components/Logout'
import CreditNoteAdd from './components/CreditNoteAdd'
import Receipts from './components/Receipts'
import Sidebar from './components/Sidebar'
import './App.css'

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
  }, []);

  return (
    <ChakraProvider>
        <BrowserRouter>
          {currentUser && <Sidebar/>}
          {!currentUser && <Login/>}
          <Routes>
            {/*<Route path='*' element={<h1>NOT FOUND 404</h1>}/>*/}
            <Route path='/products' element={<Products/>} />
            <Route path='/customers' element={<Customers/>} />
            <Route path='/suppliers' element={<Suppliers/>} />
            <Route path='/purchaseAdd' element={<PurchaseAdd/>} />
            <Route path='/purchases' element={<Purchases/>} />
            <Route path='/supplierCurrentAccount' element={<SupplierCurrentAccount/>} />
            <Route path='/purchasePlan' element={<PurchasePlan/>} />
            <Route path='/saleAdd' element={<SaleAdd/>} />
            <Route path='/despatchAdd' element={<DespatchAdd/>} />
            <Route path='/budgets' element={<Budgets/>} />
            <Route path='/budgetAdd' element={<BudgetAdd/>} />
            <Route path='/sales' element={<Sales/>} />
            <Route path='/despatchs' element={<Despatchs/>} />
            <Route path='/categories' element={<Categories/>} />
            <Route path='/subcategories' element={<Subcategories/>} />
            <Route path='/creditNoteAdd' element={<CreditNoteAdd/>} />
            <Route path='/receipts' element={<Receipts/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/logout' element={<Logout/>} />
          </Routes>
        </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
