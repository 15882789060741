import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import { useEffect } from "react";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        authService.logout()
        navigate("/");
        window.location.reload();
    })

    return (
        <>
        </>
    )
}

export default Logout