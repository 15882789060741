import { useMemo } from "react";

export default function useColumnsReceipt() {
 const columns = useMemo(
   () => [
     {
       Header: "Forma de pago",
       accessor: "paymentMethod"
     },
     {
       Header: "Importe",
       accessor: "amount"
     }
   ],
   []
 );

 return columns;
}