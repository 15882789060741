import { useMemo } from "react";

export default function useColumnsPurchaseAdd() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Producto",
       accessor: "name"
     },
     {
       Header: "Cantidad",
       accessor: "quantity"
     },
     {
       Header: "Precio unitario",
       accessor: "unitPrice"
     },
     {
       Header: "I.V.A.",
       accessor: "avgIva"
     },
     {
       Header: "Precio total",
       accessor: "totalPrice"
     }
   ],
   []
 );

 return columns;
}