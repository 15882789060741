import { useMemo } from "react";

export default function useColumnsSuppliers() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Proveedor",
       accessor: "name"
     },
     {
       Header: "Vendedor",
       accessor: "seller"
     },
     {
       Header: "Celular",
       accessor: "celPhone"
     },
     {
       Header: "E-mail",
       accessor: "email"
     }
   ],
   []
 );

 return columns;
}