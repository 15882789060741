import { useMemo } from "react";

export default function useColumnsDespatchs() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Fecha de remito",
       accessor: "date"
     },
     {
       Header: "Cliente",
       accessor: "customer"
     },
     {
       Header: "N° de Remito",
       accessor: "despatchNumber"
     },
     {
      Header: "N° de Comp. / N° de Fact.",
      accessor: "invoiceNumber"
     },
     
     {
       Header: "Fecha de venta",
       accessor: "saleDate"
     },
     {
       Header: "Date No View",
       accessor: "dateNoView"
     }
   ],
   []
 );

 return columns;
}