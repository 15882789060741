import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Button, 
    useDisclosure, InputGroup, InputLeftAddon, Stack, useToast, Select, Spacer, Flex, 
    Tooltip } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'

const DespatchEditProduct = ({ product, handleEditQuantity }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const [quantity, setQuantity] = useState(0)
    const toast = useToast()

    const handleSubmit = () => {
        if (parseFloat(quantity) <= 0) {
            toast({
                title: "La cantidad debe ser mayor a cero",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        product = {
            ...product,
            quantity: parseFloat(quantity)
        }

        handleEditQuantity(product)

        onClose()
    }

    const handleOpen = () => {        
        setQuantity(parseFloat(product.quantity).toFixed(2))
        onOpen()
    }

    return (
    <>
        <Tooltip label='Editar cantidad'><Button size="sm" colorScheme='yellow' onClick={handleOpen}><EditIcon/></Button></Tooltip>

        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={onClose}
            size='lg'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Editar cantidad</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Cantidad' width={127} />
                            <Input ref={initialRef} type='number' step='0.01' value={quantity} onChange={(e) => setQuantity(e.target.value)}/>
                        </InputGroup>

                    </Stack>
                    <Flex p={4}>
                        <Spacer/>
                        <Button colorScheme='blue' mr={3} onClick={handleSubmit}>Aceptar</Button>
                        <Button onClick={onClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

            </ModalContent>
        </Modal>
    </>
    )
}

export default DespatchEditProduct