import React from 'react'
import { Textarea } from '@chakra-ui/react'

export const GlobalFilter = ({ filter, setFilter }) => {

    return (
        <Textarea
            placeholder='Buscador...'
            size='lg'
            value={filter || ''} 
            onChange={(e) => setFilter(e.target.value)}
        />
        
    )
}