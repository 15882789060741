import { Button, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    useDisclosure, Stack, Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Box, InputGroup, InputLeftAddon, Input, Spacer, Select, 
    useToast, FormLabel, Flex, Divider } from "@chakra-ui/react"
import 'moment/locale/es'
import { useTable, useSortBy, usePagination } from "react-table"
import useColumnsPayments from "../hooks/useColumnsPayments"
import { DeleteIcon } from '@chakra-ui/icons'
import { BsCurrencyDollar, BsCheckCircle } from "react-icons/bs"
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')


const PurchasePayments = ({ idPurchase, price, handleSubmitModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef()
    const { register, formState: { errors }, reset, setValue, getValues } = useForm()
    const [date, setDate] = useState(new Date())
    const [paymentMethods, setPaymentMethods] = useState([])
    //const [paymentMethod, setPaymentMethod] = useState()
    //const [amount, setAmount] = useState()
    const [data, setData] = useState([])
    const [payments, setPayments] = useState()
    const [balance, setBalance] = useState()
    const toast = useToast()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        reset()

        fetch("https://api.veterinariacajeao.com.ar/api/paymentMethod", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
        const arrPaymentMethods = data.map(paymentMethod => {
            return { 
                id: paymentMethod._id,
                name: paymentMethod.name
            }
        })
        setPaymentMethods(arrPaymentMethods)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        fetch("https://api.veterinariacajeao.com.ar/api/purchase/" + idPurchase, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {            
            const arrPayments = data.payments.map(payment => {
            return { 
                id: payment.id,
                date: payment.date,
                formatDate: moment(payment.date).add(3,"h").format('L'),
                paymentMethod: payment.paymentMethod,
                amount: parseFloat(payment.amount)
            }
        })
            setData(arrPayments)
            let sumPayments = 0

            if (arrPayments.length > 0) {
                for (let i=0; i < arrPayments.length; i++) {
                    sumPayments = parseFloat(sumPayments) + parseFloat(arrPayments[i].amount)
                }
                setPayments(parseFloat(sumPayments).toFixed(2))
            } else {
                setPayments(parseFloat(0).toFixed(2))
            }

            //let totalPurchase = price.slice(1 ,-1)
            setBalance(parseFloat(parseFloat(price) - parseFloat(sumPayments)).toFixed(2))
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setDate(new Date())
        onOpen()
    }

    const columns = useColumnsPayments();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "date"], sortBy: [{id: "date", asc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const settlerAmount = () => {
        const accessToken = localStorage.getItem("user")
        
        if (date === null) {
            toast({
                title: "Ingrese una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }

        if (getValues("paymentMethod") === undefined || getValues("paymentMethod") === "" || getValues("paymentMethod") === null) {
            toast({
                title: "Seleccione una forma de pago",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }

        if (parseFloat(balance) === 0) {
            return
        }

        const formatDate = moment(date).format('L')

        const payment = {
            date: formatDate.split("/").reverse().join("-"),
            paymentMethod: getValues("paymentMethod"),
            amount: parseFloat(balance)
        }

        fetch("https://api.veterinariacajeao.com.ar/api/purchase/savePayment/" + idPurchase, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(payment)
        })
        .then(response => response.json())
        .then(data => {            
            const arrPayments = data.payments.map(payment => {
            return { 
                id: payment.id,
                date: payment.date,
                formatDate: moment(payment.date).add(3,"h").format('L'),
                paymentMethod: payment.paymentMethod,
                amount: parseFloat(payment.amount)
            }
        })
            setData(arrPayments)
            let sumPayments = 0

            if (arrPayments.length > 0) {
                for (let i=0; i < arrPayments.length; i++) {
                    sumPayments = parseFloat(sumPayments) + parseFloat(arrPayments[i].amount)
                }
                setPayments(parseFloat(sumPayments).toFixed(2))
            } else {
                setPayments(parseFloat(0).toFixed(2))
            }

            //let totalPurchase = price.slice(1 ,-1)
            setBalance(parseFloat(parseFloat(price) - parseFloat(sumPayments)).toFixed(2))
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setDate(new Date())
        reset()

        toast({
            title: "¡Pago añadido!",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    const savePayment = () => {
        const accessToken = localStorage.getItem("user")

        if (date === null) {
            toast({
                title: "Ingrese una fecha",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }

        if (getValues("paymentMethod") === undefined || getValues("paymentMethod") === "" || getValues("paymentMethod") === null) {
            toast({
                title: "Seleccione una forma de pago",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }

        if (parseFloat(getValues("amount")) === 0 || getValues("amount") === undefined || getValues("amount") === "") {
            toast({
                title: "Ingrese un importe",
                status: "error",
                duration: 3000,
                isClosable: true
            });

            return
        }

        const formatDate = moment(date).format('L')

        const payment = {
            //date: moment(new Date(date)).format('DD/MM/YYYY'),
            date: formatDate.split("/").reverse().join("-"),
            paymentMethod: getValues("paymentMethod"),
            amount: parseFloat(getValues("amount"))
        }

        fetch("https://api.veterinariacajeao.com.ar/api/purchase/savePayment/" + idPurchase, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(payment)
        })
        .then(response => response.json())
        .then(data => {
            const arrPayments = data.payments.map(payment => {
            return { 
                id: payment.id,
                date: payment.date,
                formatDate: moment(payment.date).add(3,"h").format('L'),
                paymentMethod: payment.paymentMethod,
                amount: parseFloat(payment.amount)
            }
        })
            setData(arrPayments)
            let sumPayments = 0

            if (arrPayments.length > 0) {
                for (let i=0; i < arrPayments.length; i++) {
                    sumPayments = parseFloat(sumPayments) + parseFloat(arrPayments[i].amount)
                }
                setPayments(parseFloat(sumPayments).toFixed(2))
            } else {
                setPayments(parseFloat(0).toFixed(2))
            }

            //let totalPurchase = price.slice(1 ,-1)
            setBalance(parseFloat(parseFloat(price) - parseFloat(sumPayments)).toFixed(2))
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setDate(new Date())
        reset()

        toast({
            title: "¡Pago añadido!",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    const deletePayment = (idPayment) => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        
        fetch("https://api.veterinariacajeao.com.ar/api/purchase/deletePayment/" + idPurchase + "/" + idPayment, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            let sumPayments = 0
            if (data.payments) {
                const arrPayments = data.payments.map(payment => {
                    return { 
                        id: payment.id,
                        date: payment.date,
                        formatDate: moment(payment.date).add(3,"h").format('L'),
                        paymentMethod: payment.paymentMethod,
                        amount: parseFloat(payment.amount)
                    }
                })
                setData(arrPayments)
    
                if (arrPayments.length > 0) {
                    for (let i=0; i < arrPayments.length; i++) {
                        sumPayments = parseFloat(sumPayments) + parseFloat(arrPayments[i].amount)
                    }
                    setPayments(parseFloat(sumPayments).toFixed(2))
                } else {
                    setData([])
                    setPayments(parseFloat(0).toFixed(2))
                }
            }
            setBalance(parseFloat(parseFloat(price) - parseFloat(sumPayments)).toFixed(2))
            
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setDate(new Date())
        reset()

        toast({
            title: "¡Pago eliminado!",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    const handleClose = () => {
        handleSubmitModal()
        onClose()
    }

    return (
        <>
            <Tooltip label='Imputar pagos'><Button size="sm" colorScheme="linkedin" onClick={handleOpen}><BsCurrencyDollar/></Button></Tooltip>
        
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Imputar pagos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <div style={{padding: "10px", borderRadius: "5px", borderStyle: "solid", borderColor: "gray", borderWidth: "1px"}}>
                            <form>
                                <Stack spacing={4}>

                                    <InputGroup mt={4} display='flex' alignItems='center'>
                                        <InputLeftAddon children='Fecha' width={140} />
                                        <DatePicker locale='es' selected={date} onChange={(date) => setDate(date)} dateFormat="dd/MM/yyyy" />
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Formas de pago' width={140} />
                                        <Select placeholder='-Selecciona-' {...register('paymentMethod', {required: 'Por favor seleccione la forma de pago'})}>
                                        {paymentMethods.map(paymentMethod => {
                                            return (
                                            <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.name}</option>
                                        )})}
                                        </Select>
                                    </InputGroup>

                                    <InputGroup>
                                        <InputLeftAddon children='Importe $' width={140} />
                                        <Input placeholder='$' type='number' step='0.01' {...register('amount')} />
                                        <Tooltip label='Guardar pago'><Button colorScheme="whatsapp" onClick={savePayment}><BsCheckCircle/></Button></Tooltip>
                                    </InputGroup>

                                    <Flex>
                                        <Box>
                                            <FormLabel textAlign="right">Total de la compra: </FormLabel>
                                            <FormLabel textAlign="right">Suma de sus pagos: </FormLabel>
                                            <Divider/>
                                            <FormLabel textAlign="right">Saldo: </FormLabel>
                                        </Box>
                                        <Box>
                                            <FormLabel textAlign="left">${price}</FormLabel>
                                            <FormLabel textAlign="left">${payments}</FormLabel>
                                            <Divider/>
                                            <FormLabel textAlign="left" bg="red">${balance}</FormLabel>
                                        </Box>
                                    </Flex>

                                    <Box paddingTop="10px" textAlign="center">
                                        <Button colorScheme="whatsapp" onClick={settlerAmount}>Saldar importe</Button>
                                    </Box>
                            
                                    <Spacer/>

                                </Stack>
                            </form>

                            <TableContainer paddingTop="15px">
                                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={(e) => deletePayment(row.values.id)}><DeleteIcon/></Button></Tooltip>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PurchasePayments