import { Box, Button, FormControl, FormLabel, Input, Flex, Heading } from '@chakra-ui/react'
import ErrorMessage from './ErrorMessage'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await authService.login(username, password).then(
                (data) => {
                    if (data) {
                        navigate("/");
                        window.location.reload();
                    } else {
                        setError('Usuario o contraseña inválidos')
                        setUsername('');
                        setPassword('');
                    }
                },
                (error) => {
                    setError('Usuario o contraseña inválidos')
                    setUsername('');
                    setPassword('');
                }
            );
        } catch (err) {
            setError('Usuario o contraseña inválidos')
            setUsername('');
            setPassword('');
        }
      };

    /*const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await authService.login(username, password).then(
                (data) => {
                    if (data) {
                        navigate("/ticket");
                        window.location.reload();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (err) {
            console.log(err);
        }
    }*/

    return (
        <>
            <Flex width="full" align="center" justifyContent="center" paddingTop="50px">
                <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Box textAlign="center">
                        <Heading>Login</Heading>
                    </Box>

                    <Box my={4} textAlign="left">
                        <form onSubmit={handleSubmit}>
                            {error && <ErrorMessage message={error} />}
                            <FormControl isRequired>
                                <FormLabel>Usuario</FormLabel>
                                <Input size="lg" onChange={event => setUsername(event.currentTarget.value)}/>
                            </FormControl>
                            <FormControl isRequired mt={6}>
                                <FormLabel>Contraseña</FormLabel>
                                <Input type="password" size="lg" onChange={event => setPassword(event.currentTarget.value)}/>
                            </FormControl>
                            
                            <Button colorScheme="teal" variant="outline" type="submit" width="full" mt={4}>Aceptar</Button>
                        </form>
                    </Box>
                </Box>
            </Flex>
            {/*<Box>
                <FormControl>
                    <FormLabel>Usuario</FormLabel>
                    <Input type='email' onChange={(e) => setUsername(e.target.value)}/>
                </FormControl>

                <FormControl>
                    <FormLabel>Contraseña</FormLabel>
                    <Input type='password' onChange={(e) => setPassword(e.target.value)}/>
                </FormControl>
            </Box>

    <Button onClick={handleLogin}>Aceptar</Button>*/}
        </>
    )
}

export default Login