import { Box, Button, Divider, Table, Thead, Tbody, Tr, Th, Td, TableContainer, HStack, Tooltip, 
    CircularProgress, useToast, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, 
    useDisclosure } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { DeleteIcon } from '@chakra-ui/icons'
import useColumnsSuppliers from "../hooks/useColumnsSuppliers";
import '../css/stylesheet.css'
import { GlobalFilter } from './GlobalFilter'
import { useEffect, useState, useRef } from 'react'
import SupplierAdd from './SupplierAdd'
import SupplierEdit from './SupplierEdit'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const Suppliers = () => {
    const [data, setData] = useState([])
    const [submitModal, setSubmitModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [supplier, setSupplier] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast = useToast()

    const navigate = useNavigate();
 
    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSuppliers = data.map(supplier => {
                return {
                    id: supplier._id,
                    name: supplier.name,
                    seller: supplier.seller,
                    celPhone: supplier.celPhone,
                    email: supplier.email
                }
            })
            setData(arrSuppliers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })        

    }, [submitModal])

    const columns = useColumnsSuppliers();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
      } = table;

      const { globalFilter } = state

      const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
      }

      const handleDelete = (idSupplier) => {
        setSupplier(idSupplier)
        onOpen()
      }

      const deleteSupplier = () => {
        const accessToken = localStorage.getItem("user")

        fetch('https://api.veterinariacajeao.com.ar/api/supplier/' + supplier, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.moviments === true) {
                toast({
                    title: "¡El proveedor se ha eliminado correctamente!",
                    status: "success",
                    duration: 3000,
                    isClosable: true
                });
                handleSubmitModal()
            } else {
                toast({
                    title: "No se puede eliminar el proveedor porque tiene movimientos cargados",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
            onClose()
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
      }
      

    return(
        <>
        <Box className="content">

            <SupplierAdd handleSubmitModal={handleSubmitModal}/>

            <Box paddingTop="10px">
                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
            </Box>

            <Divider paddingTop="15px"/>

            {loading && <Box paddingTop="15px" textAlign='center'> 
                <CircularProgress isIndeterminate color='green.300' />
            </Box>}

            {!loading && <TableContainer paddingTop="15px">
                <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                    <Thead>
                        {
                            // Recorremos las columnas que previamente definimos
                            headerGroups.map(headerGroup => (
                                // Añadimos las propiedades al conjunto de columnas
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Recorremos cada columna del conjunto para acceder a su información
                                    headerGroup.headers.map((column) => (
                                    // Añadimos las propiedades a cada celda de la cabecera
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                        {
                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                        column.render("Header")
                                        }
                                    </Th>
                                    ))
                                }
                                </Tr>
                            ))
                        }
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                            // Recorremos las filas
                            page.map(row => {
                                // Llamamos a la función que prepara la fila previo renderizado
                                prepareRow(row);
                                return (
                                // Añadimos las propiedades a la fila
                                <Tr {...row.getRowProps()}>
                                    {
                                    // Recorremos cada celda de la fila
                                    row.cells.map((cell) => {
                                        // Añadimos las propiedades a cada celda de la fila
                                        return (
                                        <Td {...cell.getCellProps()}>
                                            {
                                            // Pintamos el contenido de la celda
                                            cell.render("Cell")
                                            }
                                        </Td>
                                        );
                                    })
                                    }
                                    <Td>
                                        <HStack>
                                            <SupplierEdit idSupplier={row.values.id} handleSubmitModal={handleSubmitModal}/>
                                            <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={(e) => handleDelete(row.values.id)}><DeleteIcon/></Button></Tooltip>
                                        </HStack>
                                    </Td>
                                </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>}

            {!loading && <Box paddingTop="10px" textAlign="center">
                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
            </Box>}

            <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar proveedor
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar el proveedor?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                No
                            </Button>
                            <Button colorScheme='blue' onClick={deleteSupplier} ml={3}>
                                Si
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            
        </Box>

        </>
        

    )
}

export default Suppliers