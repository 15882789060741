import { useMemo } from "react";

export default function useColumnsPurchases() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
      Header: "Fecha",
      accessor: "date"
     },
     {
      Header: "Fecha",
      accessor: "dateFormat"
     },
     {
       Header: "Cliente",
       accessor: "customer"
     },
     {
       Header: "CUIT",
       accessor: "cuit"
     },
     {
       Header: "N° de comp. - N° de fact.",
       accessor: "voucherNumber"
     },
     {
       Header: "Tipo de comp.",
       accessor: "voucherLetter"
     },
     {
       Header: "Importe",
       accessor: "price"
     },
     {
       Header: "isInvoice",
       accessor: "isInvoice"
     }
   ],
   []
 );

 return columns;
}