import { useMemo } from "react";

export default function useColumnsReceipts() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
      Header: "Fecha",
      accessor: "date"
     },
     {
      Header: "Fecha",
      accessor: "dateNoView"
     },
     {
       Header: "Cliente",
       accessor: "customer"
     },
     {
       Header: "N° de Recibo",
       accessor: "receiptNumber"
     },
     {
       Header: "Importe",
       accessor: "amount"
     }
   ],
   []
 );

 return columns;
}