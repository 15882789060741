import { useMemo } from "react";

export default function useColumnsSaleAdd() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Producto",
       accessor: "name"
     },
     {
       Header: "N° de serie",
       accessor: "serialNumber"
     },
     {
       Header: "Cantidad",
       accessor: "quantity"
     },
     {
       Header: "Garantía",
       accessor: "warranty"
     },
     {
       Header: "Desc. %",
       accessor: "discount"
     },
     {
       Header: "Precio unit.",
       accessor: "unitPrice"
     },
     {
       Header: "I.V.A.",
       accessor: "avgIva"
     },
     {
       Header: "Precio total",
       accessor: "totalPrice"
     }
   ],
   []
 );

 return columns;
}