import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormLabel,
        Input, Button, ButtonGroup, IconButton, useDisclosure, Textarea, Switch, InputGroup, 
        InputLeftAddon, Stack, useToast, Box, Select, Spacer, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const ProductAdd = ({handleSubmitModal}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const toast = useToast( )
  const [idProduct, setIdProduct] = useState(0)
  const [categories, setCategories] = useState([])
  //const [subcategories, setSubcategories] = useState([])
  const [purchasePrice, setPurchasePrice] = useState(0)
  const [avgIva, setAvgIva] = useState(21)
  const [avgProfit, setAvgProfit] = useState(0)
  const [price, setPrice] = useState(0)

  const navigate = useNavigate();

  const handleChangePurchasePrice = (e) => setPurchasePrice(e.target.value)
  const handleChangeAvgIva = (e) => setAvgIva(e.target.value)
  const handleChangeAvgProfit = (e) => setAvgProfit(e.target.value)
  const handleChangePrice = (e) => setPrice(e.target.value)

  const onSubmit = data => {
    const accessToken = localStorage.getItem("user")

    const dataPurchasePrice = parseFloat(purchasePrice).toFixed(2) || 0
    const dataAvgIva = parseFloat(avgIva).toFixed(2) || 0
    const dataAvgProfit = parseFloat(avgProfit).toFixed(2) || 0
    const dataPrice = parseFloat(price).toFixed(2) || 0

    const product = {
      id: parseInt(idProduct),
      name: data.name,
      category: data.category,
      //subcategory: data.subcategory,
      stock: parseFloat(data.stock) || 0,
      minimumStock: parseInt(data.minimumStock) || 0,
      maximumStock: parseInt(data.maximumStock) || 0,
      ubication: data.ubication,
      warranty: parseInt(data.warranty) || 0,
      purchasePrice: parseFloat(dataPurchasePrice) || 0,
      avgIva: parseFloat(dataAvgIva) || 0,
      avgProfit: parseFloat(dataAvgProfit) || 0,
      price: parseFloat(dataPrice) || 0,
      code: data.code,
      observations: data.observations,
      isActive: data.isActive
    }

    //console.log(product)

    fetch("https://api.veterinariacajeao.com.ar/api/product", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "x-auth-token": accessToken
      },
      body: JSON.stringify(product)
    })
    .catch(error => {
      authService.logout()
      navigate("/");
      window.location.reload();
    })

    toast({
      title: "¡Producto añadido!",
      status: "success",
      duration: 3000,
      isClosable: true
    });

    handleSubmitModal()
    onClose()
  }

  const handleOpen = () => {
    const accessToken = localStorage.getItem("user")

    fetch("https://api.veterinariacajeao.com.ar/api/product/last/id", {
      headers: {
          'Content-Type': 'application/json',
          "x-auth-token": accessToken
      }
    })
    .then(response => response.json())
    .then(data => {
      setIdProduct(data)
    })
    .catch(error => {
      authService.logout()
      navigate("/");
      window.location.reload();
    })

    fetch("https://api.veterinariacajeao.com.ar/api/category", {
      headers: {
          'Content-Type': 'application/json',
          "x-auth-token": accessToken
      }
    })
    .then(response => response.json())
    .then(data => {
      const arrCategories = data.map(category => {
        return { 
            id: category._id,
            name: category.name
        }
    })
    setCategories(arrCategories)
    })
    .catch(error => {
      authService.logout()
      navigate("/");
      window.location.reload();
    })
    setPurchasePrice(0)
    setAvgIva(21)
    setAvgProfit(0)
    setPrice(0)

    reset()
    onOpen()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  /*const handleChange = (e) => {
    const accessToken = localStorage.getItem("user")

    if (e.target.value === "") {
      setSubcategories([])
      return
    }

    fetch("https://api.veterinariacajeao.com.ar/api/subcategory/category/" + e.target.value, {
      headers: {
          'Content-Type': 'application/json',
          "x-auth-token": accessToken
      }
    })
    .then(response => response.json())
    .then(data => {
      const arrSubcategories = data.map(subcategory => {
        return { 
            id: subcategory._id,
            name: subcategory.name
        }
    })
    setSubcategories(arrSubcategories)
    })
    .catch(error => {
      authService.logout()
      navigate("/");
      window.location.reload();
    })
  }*/

  const calculatingPrice = () => {
    const priceWithIva = (parseFloat(purchasePrice) * parseFloat(avgIva) / 100) + parseFloat(purchasePrice)
    setPrice(parseFloat((parseFloat(priceWithIva) * parseFloat(avgProfit) / 100) + parseFloat(priceWithIva)).toFixed(2))
  }

  const calculatingAvgProfit = () => {
    const priceWithIva = (parseFloat(purchasePrice) * parseFloat(avgIva) / 100) + parseFloat(purchasePrice)
    setAvgProfit(parseFloat(((parseFloat(price) * 100 / parseFloat(priceWithIva)) - 100)).toFixed(2))
  }

  return (
    <>
      <ButtonGroup size='sm' isAttached>
            <Button colorScheme="whatsapp" onClick={handleOpen}>Nuevo producto</Button>
            <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
        </ButtonGroup>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={handleClose}
        size='lg'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo producto</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon children='ID' />
                <Input placeholder='ID' isDisabled required value={idProduct} />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon children='Nombre' width={127} />
                <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del producto'})} />
              </InputGroup>
              {errors.name && <AlertPop title={errors.name.message} />}

              <InputGroup>
                <InputLeftAddon children='Categoría' width={127} />
                <Select placeholder='-Selecciona-' {...register('category', /*{onChange: (e) => handleChange(e)},*/ {required: 'Por favor seleccione la categoría'})}>
                  {categories.map(category => {
                    return (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    )})}
                </Select>
              </InputGroup>
              {errors.category && <AlertPop title={errors.category.message} />}

              {/*<InputGroup>
                <InputLeftAddon children='Subcategoría' width={127} />
                <Select placeholder='-Selecciona-' {...register('subcategory', {required: 'Por favor seleccione la subcategoría'})}>
                  {subcategories.map(subcategory => {
                    return (
                      <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                  )})}
                </Select>
              </InputGroup>
                    {errors.subcategory && <AlertPop title={errors.subcategory.message} />}*/}

              <InputGroup>
                <InputLeftAddon children='Stock' width={127} />
                <Input placeholder='Stock' type='number' step='0.01' {...register('stock', {min: 0})} />
              </InputGroup>
              {errors.stock && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Stock mín.' width={127} />
                <Input placeholder='Stock mínimo' type='number' {...register('minimumStock', {min: 0})} />
              </InputGroup>
              {errors.minimumStock && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Stock máx.' width={127} />
                <Input placeholder='Stock máximo' type='number' {...register('maximumStock', {min: 0})} />
              </InputGroup>
              {errors.maximumStock && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Ubicación' width={127} />
                <Input placeholder='Ubicación' {...register('ubication')} />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon children='Garantía' width={127} />
                <Input placeholder='Garantía (en meses)' type='number' {...register('warranty', {min: 0})} />
              </InputGroup>
              {errors.warranty && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Pre. compra $' width={127} />
                <Input placeholder='$' type='number' step='0.01' value={purchasePrice} {...register('purchasePrice', {onBlur: calculatingPrice, onChange: (e) => handleChangePurchasePrice(e), required: 'Por favor ingrese el nombre del producto', min: 0})} />
              </InputGroup>
              {errors.purchasePrice && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='I.V.A. %' width={127} />
                <Input placeholder='%' type='number' step='0.01' value={avgIva} {...register('avgIva', {onBlur: calculatingPrice, onChange: (e) => handleChangeAvgIva(e), required: 'Por favor ingrese el nombre del producto', min: 0})} />
              </InputGroup>
              {errors.avgIva && <AlertPop title={"El número debe ser mayor a 0"} />}

              {/*<InputGroup>
                <InputLeftAddon children='I.V.A. $' width={127} />
                <Input placeholder='$' type='number' step='0.01' {...register('priceIva', {min: 0})} />
              </InputGroup>
                    {errors.priceIva && <AlertPop title={"El número debe ser mayor a 0"} />}*/}

              <InputGroup>
                <InputLeftAddon children='Ganancia %' width={127} />
                <Input placeholder='%' type='number' step='0.01' value={avgProfit} {...register('avgProfit', {onBlur: calculatingPrice, onChange: (e) => handleChangeAvgProfit(e), required: 'Por favor ingrese el nombre del producto', min: 0})} />
              </InputGroup>
              {errors.avgProfit && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Precio $' width={127} />
                <Input placeholder='$' type='number' step='0.01' value={price} {...register('price', {onBlur: calculatingAvgProfit, onChange: (e) => handleChangePrice(e), required: 'Por favor ingrese el nombre del producto', min: 0})} />
              </InputGroup>
              {errors.price && <AlertPop title={"El número debe ser mayor a 0"} />}

              <InputGroup>
                <InputLeftAddon children='Cód. barras' width={127} />
                <Input placeholder='Código de barras' {...register('code')} />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon children='Observaciones' width={127} />
                <Textarea placeholder='Observaciones' {...register('observations')} />
              </InputGroup>

              <Box mt={4} display='flex' alignItems='center'>
                <FormLabel >
                  Activo
                </FormLabel>
                <Switch id='isActive' defaultChecked='true' {...register('isActive')} />
              </Box>
            </Stack>
            <Flex p={4}>
              <Spacer/>
              <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </Flex>
            
          </form>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default ProductAdd