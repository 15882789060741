import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    size: "A4"
  },
  container: {
    padding: "25px"
  },
  headerContainer: {
    display: "flex",
    height: "30px",
    textAlign: "center"
  },
  productsContainer: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "730px",
    marginTop: "2px"
  },
  titleProductsContainer: {
    height: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "8",
    fontWeight: "bold",
    flexDirection: "row"
  },
  codigTitle: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  detailTitle: {
    width: "350px",
    textAlign: "center",
    paddingTop: "3px"
  },
  stockTitle: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  quantityTitle: {
    width: "85px",
    textAlign: "center",
    paddingTop: "3px"
  },
  products: {
    fontSize: "10",
    flexDirection: "row"
  },
  productDetail: {
    width: "350px",
    textAlign: "left",
    paddingTop: "3px"
  }
});

// Create Document Component
const PDFProductsUndelivered = ({ products }) => (
  
  <Document>
    <Page style={styles.page}>
        <View style={styles.container}>
            <View style={styles.headerContainer}>
              <Text>LISTADO DE PRODUCTOS</Text>
            </View>
            <View style={styles.productsContainer}>
              <View style={styles.titleProductsContainer}>
                <View style={styles.codigTitle}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={styles.detailTitle}>
                  <Text>DETALLE</Text>
                </View>
                <View style={styles.stockTitle}>
                  <Text>STOCK</Text>
                </View>
                <View style={styles.quantityTitle}>
                  <Text>PEND. DE ENTREGAR</Text>
                </View>
              </View>
              <View style={styles.products}>
                <View style={styles.codigTitle}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.id}</Text>
                  ))}
                </View>
                <View style={styles.productDetail}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.name}</Text>
                  ))}
                </View>
                <View style={styles.stockTitle}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.stock}</Text>
                  ))}
                </View>
                <View style={styles.quantityTitle}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.quantity}</Text>
                  ))}
                </View>
              </View>
            </View>
        </View>
    </Page>
  </Document>
);

export default PDFProductsUndelivered