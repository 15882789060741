import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure,
    Button, Tooltip } from '@chakra-ui/react'
import { BsPrinter } from "react-icons/bs"
import { useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PDFDespatch from './PDFDespatch'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import moment from 'moment'

const PrintPDFDespatch = ({ idDespatch }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    let [ form, setForm ] = useState({})

    const navigate = useNavigate();

    const handleClose = () => {
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        
        fetch("https://api.veterinariacajeao.com.ar/api/despatch/" + idDespatch, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setForm(form = {
                customerName: data.sale.customer.name,
                customerStreet: data.sale.customer.street,
                customerIva: data.sale.customer.ivaName,
                customerCity: data.sale.customer.city,
                customerCuit: data.sale.customer.cuit,
                customerState: data.sale.customer.stateName,
                despatchNumber: data.despatchNumber,
                invoiceNumber: data.sale.invoiceNumber + " / " + data.sale.invoiceNumberAfip,
                date: moment(data.date).add(3,"h").format("L"),
                products: data.products,
                observations: data.observations
            })
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
        
        onOpen()
    }

    return (
        <>
            <Tooltip label='Imprimir'><Button size="sm" colorScheme='messenger' onClick={handleOpen}><BsPrinter/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Remito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <PDFViewer width="720px" height="400px">
                            <PDFDespatch {...form}/>
                        </PDFViewer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default PrintPDFDespatch