import { TYPES } from '../actions/cartAction'

export const cartInitialState = {
    data: []
}

export function cartReducer(state, action) {
    switch (action.type) {
        case TYPES.ADD_TO_CART: {
            let product = state.data.find(obj => obj.id === action.payload.id)

            return product
            ? {
                ...state,
                data: state.data.map(obj => 
                    obj.id === product.id
                    ? {...obj, quantity: parseFloat(parseFloat(obj.quantity) + parseFloat(action.payload.quantity)).toFixed(2), unitPrice: parseFloat(action.payload.unitPrice).toFixed(2),totalPrice: parseFloat((parseFloat(obj.quantity) + parseFloat(action.payload.quantity)) * parseFloat(action.payload.unitPrice)).toFixed(2)}
                    : {...obj}),
            }
            : {
                ...state,
                data: [...state.data, {...action.payload, quantity: parseFloat(action.payload.quantity).toFixed(2)} ]
            }

            /*return {
                ...state,
                data: [...state.data, {...action.payload, quantity: action.payload.quantity} ]
            }*/
        }
        case TYPES.REMOVE_ONE_FROM_CART: {
            return {
                ...state,
                data: state.data.filter(obj => obj.id !== action.payload)
            }
        }
        case TYPES.CLEAR_CART: {
            return cartInitialState
        }
        default:
            return state
    }
}