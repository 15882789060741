import { useMemo } from "react";

export default function useColumnsCustomers() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "Cliente",
       accessor: "name"
     },
     {
       Header: "Condición I.V.A.",
       accessor: "iva"
     },
     {
       Header: "C.U.I.T.",
       accessor: "cuit"
     },
     /*{
       Header: "Dirección",
       accessor: "street"
     },*/
     {
       Header: "Celular",
       accessor: "celPhone"
     }/*,
     {
       Header: "E-mail",
       accessor: "email"
     }*/
   ],
   []
 );

 return columns;
}