import { Box, Button, Flex, Spacer, Divider, FormLabel, Table, Thead, Tbody, Tr, Th, Td, TableContainer, 
    Select, HStack, Tooltip, Stack, CircularProgress, useDisclosure, AlertDialog, 
    AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useToast } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { cartInitialState, purchasePlanReducer } from '../reducers/purchasePlanReducer';
import { TYPES } from '../actions/cartAction';
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { useState, useReducer, useRef } from 'react'
import useColumnsPurchasePlanAdd from "../hooks/useColumnsPurchasePlanAdd";
import { GlobalFilter } from './GlobalFilter'
import PurchasePlanProductAdd from './PurchasePlanProductAdd';
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
const moment = require('moment')

const PurchasePlanAdd = ({ handleSubmitModal }) => {
    const [stateReducer, dispatch] = useReducer(purchasePlanReducer, cartInitialState)
    const { data } = stateReducer
    const [loading, setLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const [suppliers, setSuppliers] = useState([])
    const [supplier, setSupplier] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0)
    const toast = useToast()
    const cancelRefAlert = useRef()

    const navigate = useNavigate();

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSuppliers = data.map(supplier => {
                return { 
                    id: supplier._id,
                    name: supplier.name
                }
            })
            setSuppliers(arrSuppliers)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        setTotalPrice(0)
        setSupplier(null)
        dispatch({ type:TYPES.CLEAR_CART })
        onOpen()
    }

    const columns = useColumnsPurchasePlanAdd();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["_id"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
    } = table

    const { globalFilter } = state

    const addToCart = (product) => {
        dispatch({ type:TYPES.ADD_TO_CART, payload: product })
        calculatingTotalPrice(product, true)
    }

    const removeFromCart = (product) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: product._id })
        calculatingTotalPrice(product, false)
    }

    const calculatingTotalPrice = (product, add) => {
        let sumTotalPrice = 0
        for (let i=0; i < data.length; i++) {
            if (data[i]._id === product._id) continue
            sumTotalPrice = parseFloat(sumTotalPrice) + parseFloat(data[i].totalPrice)
        }

        if (add === true) {
            const productSearch = data.find(obj => obj._id === product._id)
            if (productSearch) {
                const quantityProduct = parseFloat(productSearch.quantity) + parseFloat(product.quantity)
                const totalPriceProduct = parseFloat(quantityProduct) * parseFloat(product.price)
                sumTotalPrice = parseFloat(sumTotalPrice) + parseFloat(totalPriceProduct)
            } else {
                sumTotalPrice = parseFloat(sumTotalPrice) + parseFloat(product.totalPrice)
            }
        }

        setTotalPrice(parseFloat(sumTotalPrice).toFixed(2))
    }

    const handleSubmit = () => {
        if (data.length === 0){
            toast({
                title: "No hay productos cargados",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        /*if (supplier === null){
            toast({
                title: "Seleccione un proveedor",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }*/

        onOpenAlert()
    }

    const confirmPurchasePlan = () => {
        const accessToken = localStorage.getItem("user")

        moment.locale("en")
        const arrProductsData = []

        for (let i=0; i < data.length; i++) {
            arrProductsData.push({
                _id: data[i]._id,
                quantity: parseFloat(data[i].quantity),
                price: parseFloat(data[i].price)
            })
        }

        const purchasePlan = {
            supplier: supplier,
            date: moment().format('L'),
            products: arrProductsData,
            totalPrice: parseFloat(totalPrice)
        }
        
        fetch("https://api.veterinariacajeao.com.ar/api/purchasePlan", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(purchasePlan)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "La planificación de compra se cargó correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onCloseAlert()
        onClose()
    }

    return (
        <>
            <Button size='sm' colorScheme='cyan' color='white' onClick={handleOpen}>Planificar compra manualmente</Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='5xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Planificar compra</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <HStack paddingTop="10px">
                                <FormLabel>Proveedor:</FormLabel>
                                <Select placeholder='-Selecciona-' width="300px" onChange={(e) => setSupplier(e.target.value)}>
                                {suppliers.map(supplier => {
                                    return (
                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                    )})}
                                </Select>
                            </HStack>

                            <Divider paddingTop="10px"/>

                            <Box paddingTop="10px">
                                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                            </Box>

                            <Box paddingTop="10px">
                                <PurchasePlanProductAdd addToCart={addToCart}/>
                            </Box>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>

                            <Flex paddingTop="10px">
                                <Spacer/>
                                <Box>
                                    <FormLabel textAlign="right">Precio total: </FormLabel>
                                </Box>
                                <Box>
                                    <FormLabel textAlign="left">${totalPrice}</FormLabel>
                                </Box>
                            </Flex>

                            <Flex>
                                <Spacer/>
                                <Button colorScheme='gray' mr={3} onClick={onClose}>Cancelar</Button>
                                <Button colorScheme='blue' mr={3} onClick={handleSubmit}>Guardar</Button>
                            </Flex>
                        </Stack>
                    </ModalBody>

                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirmar planificación de compra
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la planificación de compra?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={confirmPurchasePlan} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )

}

export default PurchasePlanAdd