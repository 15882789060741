import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    size: "A4"
  },
  container: {
    padding: "25px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "100px"
  },
  headerLeft: {
    flex: 1,
    textAlign: "left",
    fontSize: "10"
  },
  TitleHeaderLeft: {
    textAlign: "center",
    fontSize: "20"
  },
  headerRight: {
    flex: 1,
    textAlign: "left",
    paddingLeft: "10px"
  },
  headerRightPrincipal: {
    height: "90px"
  },
  headerLetter: {
    width: "40px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black"
  },
  letter: {
    height: "30px",
    borderStyle: "solid",
    borderWidth: "1",
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    fontSize: "25"
  },
  clientContainer: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "70px",
    marginTop: "2px"
  },
  clientLeft: {
    flex: 1,
    fontSize: "12"
  },
  clientRight: {
    flex: 1,
    fontSize: "12"
  },
  productsContainer: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    height: "450px",
    marginTop: "2px"
  },
  titleProductsContainer: {
    height: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "8",
    fontWeight: "bold",
    flexDirection: "row"
  },
  codigTitle: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  detailTitle: {
    width: "270px",
    textAlign: "center",
    paddingTop: "3px"
  },
  quantityTitle: {
    width: "42px",
    textAlign: "center",
    paddingTop: "3px"
  },
  unitPriceTitle: {
    width: "70px",
    textAlign: "center",
    paddingTop: "3px"
  },
  discountTitle: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  totalPriceTitle: {
    width: "83px",
    textAlign: "center",
    paddingTop: "3px"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2px",
    height: "150px"
  },
  leftFooter: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4"
  },
  caeContainer: {
    flex: 1,
    flexDirection: "row"
  },
  titlesLeftFooter: {
    flex: 2,
    textAlign: "right",
    fontSize: "10"
  },
  detailsLeftFooter: {
    flex: 1,
    textAlign: "left",
    fontSize: "10"
  },
  rightFooter: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: "1",
    borderRadius: "4",
    flexDirection: "row"
  },
  titlesRightFooter: {
    flex: 2,
    textAlign: "right",
    fontSize: "12"
  },
  detailsRightFooter: {
    flex: 1,
    textAlign: "right",
    fontSize: "12"
  },
  products: {
    fontSize: "10",
    flexDirection: "row"
  },
  productCodig: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productDetail: {
    width: "270px",
    textAlign: "left",
    paddingTop: "3px"
  },
  productQuantity: {
    width: "42px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productUnitPrice: {
    width: "70px",
    textAlign: "right",
    paddingTop: "3px"
  },
  productDiscount: {
    width: "40px",
    textAlign: "center",
    paddingTop: "3px"
  },
  productTotalPrice: {
    width: "83px",
    textAlign: "right",
    paddingTop: "3px"
  },
  enter: {
    color: "white"
  }
});

// Create Document Component
const PDFCNB = ({ customerName, customerStreet, customerIva, customerCity, customerCuit, creditNoteNumber,
                        dateAfip, products, grossPrice, price, customerState, cae, caeExpiration, 
                        despatchNumber, qr }) => (
  
  <Document>
    <Page style={styles.page}>
        <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={styles.headerLeft}>
                <View style={styles.TitleHeaderLeft}>
                  <Text>VETERINARIA CAJEAO</Text>
                </View>
                <View style={styles.enter}><Text>Enter</Text></View>
                <Text>Razón social: Cajeao Pablo Manuel</Text>
                <Text>Domicilio comercial: Santa Fe 165 - Ticino, Córdoba</Text>
                <Text>Cond. frante al IVA: IVA RESPONSABLE INSCRIPTO</Text>
                <Text>Celular: 3535635614</Text>
              </View>
              <View style={styles.headerLetter}>
                <View style={styles.letter}>
                  <Text>B</Text>
                  <Text style={{color: "black", fontSize: 10, textAlign: "left"}}>e</Text>
                  <Text style={{fontSize: 10}}>COD. 08</Text>
                </View>
              </View>
              <View style={styles.headerRight}>
                <View style={styles.headerRightPrincipal}>
                  <Text style={{fontSize: "15"}}>NOTA DE CRÉDITO B</Text>
                  <Text style={{fontSize: "12"}}>ORIGINAL</Text>
                  <View style={styles.enter}>
                    <Text style={{fontSize: "12"}}>Enter</Text>
                  </View>
                  <Text style={{fontSize: "12"}}>N°: {creditNoteNumber}</Text>
                  <Text style={{fontSize: "12"}}>Fecha: {dateAfip}</Text>
                  <View style={styles.enter}>
                    <Text style={{fontSize: "12"}}>Enter</Text>
                  </View>
                  <Text style={{fontSize: "12"}}>CUIT: 20268625179</Text>
                  <Text style={{fontSize: "12"}}>Ingresos brutos: 280414921</Text>
                  <Text style={{fontSize: "12"}}>Fecha de inicio de actividades: 01/07/2024</Text>
                </View>
              </View>
            </View>
            <View style={styles.clientContainer}>
              <View style={styles.clientLeft}>
                <Text>C.U.I.T.: {customerCuit}</Text>
                <Text>Apellido y Nombre/Razón Social: {customerName}</Text>
                <Text>Domicilio: {customerStreet} - {customerCity}, {customerState}</Text>
                <Text>Cond. I.V.A.: {customerIva}</Text>
                <Text>Remito N°: {despatchNumber}</Text>
              </View>
            </View>
            <View style={styles.productsContainer}>
              <View style={styles.titleProductsContainer}>
                <View style={styles.codigTitle}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={styles.detailTitle}>
                  <Text>DETALLE</Text>
                </View>
                <View style={styles.quantityTitle}>
                  <Text>CANTIDAD</Text>
                </View>
                <View style={styles.unitPriceTitle}>
                  <Text>P. UNIT.</Text>
                </View>
                <View style={styles.discountTitle}>
                  <Text>DESC.</Text>
                </View>
                <View style={styles.totalPriceTitle}>
                  <Text>SUBTOTAL</Text>
                </View>
              </View>
              <View style={styles.products}>
                <View style={styles.productCodig}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.id}</Text>
                  ))}
                </View>
                <View style={styles.productDetail}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.name}</Text>
                  ))}
                </View>
                <View style={styles.productQuantity}>
                  {products && products.map(product => (
                    <Text key={product._id}>{parseFloat(product.quantity).toFixed(2)}</Text>
                  ))}
                </View>
                <View style={styles.productUnitPrice}>
                  {products && products.map(product => (
                    <Text key={product._id}>${parseFloat(product.unitPrice).toFixed(2)}</Text>
                  ))}
                </View>
                <View style={styles.productDiscount}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.discount}%</Text>
                  ))}
                </View>
                <View style={styles.productTotalPrice}>
                  {products && products.map(product => (
                    <Text key={product._id}>${parseFloat(product.totalPrice).toFixed(2)}</Text>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.footerContainer}>
              <View style={styles.leftFooter}>
                <View style={styles.caeContainer}>
                  <View style={styles.titlesLeftFooter}>
                    <Text>CAE N°: </Text>
                    <Text>Fecha de Vto. de CAE: </Text>
                  </View>
                  <View style={styles.detailsLeftFooter}>
                    <Text>{cae}</Text>
                    <Text>{caeExpiration}</Text>
                  </View>
                </View>
                <Image src={`${qr}`} style={{width: "100"}}/>
              </View>
              <View style={styles.rightFooter}>
                <View style={styles.titlesRightFooter}>
                  <Text>SUBTOTAL: </Text>
                  <Text>IMPORTE TOTAL: </Text>
                </View>
                <View style={styles.detailsRightFooter}>
                  <Text>${parseFloat(grossPrice).toFixed(2)}</Text>
                  <Text>${parseFloat(price).toFixed(2)}</Text>
                </View>
              </View>
            </View>
        </View>
    </Page>
    <Page style={styles.page}>
        <View style={styles.container}>
            <View style={styles.headerContainer}>
              <View style={styles.headerLeft}>
                <View style={styles.TitleHeaderLeft}>
                  <Text>VETERINARIA CAJEAO</Text>
                </View>
                <View style={styles.enter}><Text>Enter</Text></View>
                <Text>Razón social: Cajeao Pablo Manuel</Text>
                <Text>Domicilio comercial: Santa Fe 165 - Ticino, Córdoba</Text>
                <Text>Cond. frante al IVA: IVA RESPONSABLE INSCRIPTO</Text>
                <Text>Celular: 3535635614</Text>
              </View>
              <View style={styles.headerLetter}>
                <View style={styles.letter}>
                  <Text>B</Text>
                  <Text style={{color: "black", fontSize: 10, textAlign: "left"}}>e</Text>
                  <Text style={{fontSize: 10}}>COD. 08</Text>
                </View>
              </View>
              <View style={styles.headerRight}>
                <View style={styles.headerRightPrincipal}>
                  <Text style={{fontSize: "15"}}>NOTA DE CRÉDITO B</Text>
                  <Text style={{fontSize: "12"}}>DUPLICADO</Text>
                  <View style={styles.enter}>
                    <Text style={{fontSize: "12"}}>Enter</Text>
                  </View>
                  <Text style={{fontSize: "12"}}>N°: {creditNoteNumber}</Text>
                  <Text style={{fontSize: "12"}}>Fecha: {dateAfip}</Text>
                  <View style={styles.enter}>
                    <Text style={{fontSize: "12"}}>Enter</Text>
                  </View>
                  <Text style={{fontSize: "12"}}>CUIT: 20268625179</Text>
                  <Text style={{fontSize: "12"}}>Ingresos brutos: 280414921</Text>
                  <Text style={{fontSize: "12"}}>Fecha de inicio de actividades: 01/07/2024</Text>
                </View>
              </View>
            </View>
            <View style={styles.clientContainer}>
              <View style={styles.clientLeft}>
                <Text>C.U.I.T.: {customerCuit}</Text>
                <Text>Apellido y Nombre/Razón Social: {customerName}</Text>
                <Text>Domicilio: {customerStreet} - {customerCity}, {customerState}</Text>
                <Text>Cond. I.V.A.: {customerIva}</Text>
                <Text>Remito N°: {despatchNumber}</Text>
              </View>
            </View>
            <View style={styles.productsContainer}>
              <View style={styles.titleProductsContainer}>
                <View style={styles.codigTitle}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={styles.detailTitle}>
                  <Text>DETALLE</Text>
                </View>
                <View style={styles.quantityTitle}>
                  <Text>CANTIDAD</Text>
                </View>
                <View style={styles.unitPriceTitle}>
                  <Text>P. UNIT.</Text>
                </View>
                <View style={styles.discountTitle}>
                  <Text>DESC.</Text>
                </View>
                <View style={styles.totalPriceTitle}>
                  <Text>SUBTOTAL</Text>
                </View>
              </View>
              <View style={styles.products}>
                <View style={styles.productCodig}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.id}</Text>
                  ))}
                </View>
                <View style={styles.productDetail}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.name}</Text>
                  ))}
                </View>
                <View style={styles.productQuantity}>
                  {products && products.map(product => (
                    <Text key={product._id}>{parseFloat(product.quantity).toFixed(2)}</Text>
                  ))}
                </View>
                <View style={styles.productUnitPrice}>
                  {products && products.map(product => (
                    <Text key={product._id}>${parseFloat(product.unitPrice).toFixed(2)}</Text>
                  ))}
                </View>
                <View style={styles.productDiscount}>
                  {products && products.map(product => (
                    <Text key={product._id}>{product.discount}%</Text>
                  ))}
                </View>
                <View style={styles.productTotalPrice}>
                  {products && products.map(product => (
                    <Text key={product._id}>${parseFloat(product.totalPrice).toFixed(2)}</Text>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.footerContainer}>
              <View style={styles.leftFooter}>
                <View style={styles.caeContainer}>
                  <View style={styles.titlesLeftFooter}>
                    <Text>CAE N°: </Text>
                    <Text>Fecha de Vto. de CAE: </Text>
                  </View>
                  <View style={styles.detailsLeftFooter}>
                    <Text>{cae}</Text>
                    <Text>{caeExpiration}</Text>
                  </View>
                </View>
                <Image src={`${qr}`} style={{width: "100"}}/>
              </View>
              <View style={styles.rightFooter}>
                <View style={styles.titlesRightFooter}>
                  <Text>SUBTOTAL: </Text>
                  <Text>IMPORTE TOTAL: </Text>
                </View>
                <View style={styles.detailsRightFooter}>
                  <Text>${parseFloat(grossPrice).toFixed(2)}</Text>
                  <Text>${parseFloat(price).toFixed(2)}</Text>
                </View>
              </View>
            </View>
        </View>
    </Page>
  </Document>
);

export default PDFCNB