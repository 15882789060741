import { useMemo } from "react";

export default function useColumnsPurchasePlanAdd() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Producto",
       accessor: "name"
     },
     {
       Header: "Stock",
       accessor: "stock"
     },
     {
       Header: "Stock mín.",
       accessor: "minimum"
     },
     {
       Header: "Stock máx.",
       accessor: "maximum"
     },
     {
       Header: "Cantidad",
       accessor: "quantity"
     },
     {
       Header: "Precio",
       accessor: "price"
     },
     {
       Header: "Precio total",
       accessor: "totalPrice"
     }
   ],
   []
 );

 return columns;
}