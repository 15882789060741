import { useMemo } from "react";

export default function useColumnsCustomerCurrentAccount() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Fecha",
       accessor: "formatDate"
     },
     {
       Header: "Fecha",
       accessor: "date"
     },
     {
       Header: "N° de comprobante",
       accessor: "voucherNumber"
     },
     {
       Header: "Tipo de comprobante",
       accessor: "voucherLetter"
     },
     {
       Header: "Importe",
       accessor: "price"
     },
     {
       Header: "isInvoice",
       accessor: "isInvoice"
     }
   ],
   []
 );

 return columns;
}