import { HStack, Box, FormLabel, Button, Divider, Table, Thead, Tbody, Tr, Th, Td, TableContainer, 
    Tooltip, CircularProgress } from "@chakra-ui/react"
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from './GlobalFilter'
//import Budget from './Budget'
import PrintPDFBudget from "./PrintPDFBudget";
import useColumnsBudgets from "../hooks/useColumnsBudgets"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
registerLocale('es', es)
const moment = require('moment')

const Budgets = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'month')))
    const [toDate, setToDate] = useState(new Date())
    const [submitModal, setSubmitModal] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")

        setLoading(true)

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')

        const filter = {
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.veterinariacajeao.com.ar/api/budget/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrBudgets = data.map(budget => {
                return {
                    id: budget._id,
                    budgetNumber: budget.budgetNumber,
                    customer: budget.customer,
                    price: "$" + parseFloat(budget.price).toFixed(2),
                    date: moment(budget.date).add(3,"h").format('L'),
                    dateNoView: budget.date
                }
            })
            setData(arrBudgets)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }, [fromDate, toDate, submitModal])

    const columns = useColumnsBudgets();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "dateNoView"], sortBy: [{id: "dateNoView", desc: true}]} }, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
    } = table;

    const { globalFilter } = state

    const handleFromDate = (date) => {
        setFromDate(date || new Date())
    }

    const handleToDate = (date) => {
        setToDate(date || new Date())
    }

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    return(
        <>
            <Box className="content">

                <Box paddingTop="10px">
                    <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                </Box>

                <HStack paddingTop="15px">
                    <Box></Box>
                    <FormLabel>Desde:</FormLabel>
                    <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                    <FormLabel>Hasta:</FormLabel>
                    <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                </HStack>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <HStack>
                                                {/*<Budget idBudget={row.values.id} handleSubmitModal={handleSubmitModal}/>*/}
                                                <PrintPDFBudget idBudget={row.values.id}/>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}
            </Box>
        </>
    )
}

export default Budgets