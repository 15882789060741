import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    Input, Button, ButtonGroup, IconButton, useDisclosure, InputGroup, 
    InputLeftAddon, Stack, useToast, Select, Spacer, Flex } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const SubcategoryAdd = ({handleSubmitModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const toast = useToast()
    const [categories, setCategories] = useState([])

    const navigate = useNavigate();

    const onSubmit = data => {
        const accessToken = localStorage.getItem("user")
        
        const subcategory = {
            name: data.name,
            category: data.category
        }

        fetch("https://api.veterinariacajeao.com.ar/api/subcategory", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(subcategory)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "Subcategoría añadida!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        fetch("https://api.veterinariacajeao.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        reset()
        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
        <>
            <ButtonGroup size='sm' isAttached>
                <Button colorScheme="whatsapp" onClick={handleOpen}>Nueva subcategoría</Button>
                <IconButton onClick={handleOpen} aria-label='Add to friends' icon={<AddIcon />} colorScheme="whatsapp" variant="outline"/>
            </ButtonGroup>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nueva subcategoría</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4}>

                            <InputGroup>
                                <InputLeftAddon children='Nombre' width={127} />
                                <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del cliente'})} />
                            </InputGroup>
                            {errors.name && <AlertPop title={errors.name.message} />}

                            <InputGroup>
                                <InputLeftAddon children='Categoría' width={127} />
                                <Select placeholder='-Selecciona-' {...register('category', {required: 'Por favor seleccione la categoría'})}>
                                {categories.map(category => {
                                    return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                )})}
                                </Select>
                            </InputGroup>
                            {errors.category && <AlertPop title={errors.category.message} />}
                
                        </Stack>
                        <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={handleClose}>Cancelar</Button>
                        </Flex>
                        
                    </form>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default SubcategoryAdd