import { Button, Box, Divider, FormLabel, Select, HStack, CircularProgress, Flex, Spacer, Table, Thead, 
    Tbody, Tr, Th, Td, TableContainer, Tooltip } from "@chakra-ui/react"
import 'moment/locale/es'
import { useState, useEffect } from 'react'
import { useTable, useSortBy, usePagination } from "react-table";
import useColumnsPurchases from "../hooks/useColumnsPurchases"
import PurchasePlanAdd from "./PurchasePlanAdd"
import PurchasePlanAddAuto from "./PurchasePlanAddAuto"
import PrintPDFPurchasePlan from "./PrintPDFPurchasePlan"
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')

const PurchasePlan = () => {
    const [data, setData] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [supplier, setSupplier] = useState("")
    const [loading, setLoading] = useState(false)
    const [submitModal, setSubmitModal] = useState(false)
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'month')))
    const [toDate, setToDate] = useState(new Date())

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem("user")

        moment.locale("es")
        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/supplier", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrSuppliers = data.map(supplier => {
                return { 
                    id: supplier._id,
                    name: supplier.name
                }
            })
            setSuppliers(arrSuppliers)
        })
        .catch(error => {
            console.log(error)
        })

        const newFromDate = moment(fromDate).format('L')
        const newToDate = moment(toDate).format('L')
        
        const filter = {
            supplier: supplier || null,
            fromDate: newFromDate.split("/").reverse().join("-"),
            toDate: newToDate.split("/").reverse().join("-")
        }

        fetch("https://api.veterinariacajeao.com.ar/api/purchasePlan/supplier/" + filter.supplier + "/fromDate/" + filter.fromDate + "/toDate/" + filter.toDate, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrPurchasePlan = data.map(purchasePlan => {
                return {
                    id: purchasePlan._id,
                    date: purchasePlan.date,
                    dateFormat: moment(purchasePlan.date).add(3,"h").format('L'),
                    supplier: purchasePlan.supplier,
                    price: "$" + parseFloat(purchasePlan.totalPrice).toFixed(2)
                }
            })
            setData(arrPurchasePlan)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })
    }, [supplier, fromDate, toDate, submitModal])

    const columns = useColumnsPurchases();
    const table = useTable({ columns, data, initialState: {pageSize: 30, pageIndex: 0, hiddenColumns: ["id", "date", "invoiceNumber", "invoiceLetter"], sortBy: [{id: "date", desc: true}]} }, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state
    } = table

    const handleChangeSupplier = (e) => {
        setSupplier(e.target.value)
    }

    const handleFromDate = (date) => {
        setFromDate(date || new Date())
    }

    const handleToDate = (date) => {
        setToDate(date || new Date())
    }

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    return (
        <>
            <Box className="content">
                <HStack>
                    <PurchasePlanAdd handleSubmitModal={handleSubmitModal}/>
                    <PurchasePlanAddAuto handleSubmitModal={handleSubmitModal}/>
                </HStack>
            
                <Divider paddingTop="10px"/>

                <Flex paddingTop="10px">
                    <Box>
                        <HStack>
                            <FormLabel>Proveedor:</FormLabel>
                            <Select placeholder='-Selecciona-' onChange={(e) => handleChangeSupplier(e)}>
                            {suppliers.map(supplier => {
                                return (
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )})}
                            </Select>

                            <FormLabel>Desde:</FormLabel>
                            <DatePicker locale='es' selected={fromDate} onChange={(date) => handleFromDate(date)} dateFormat="dd/MM/yyyy" />

                            <FormLabel>Hasta:</FormLabel>
                            <DatePicker locale='es' selected={toDate} onChange={(date) => handleToDate(date)} dateFormat="dd/MM/yyyy" />
                        </HStack>
                    </Box>

                    <Spacer/>

                </Flex>

                <Divider paddingTop="15px"/>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <TableContainer paddingTop="15px">
                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                        <Thead>
                            {
                                // Recorremos las columnas que previamente definimos
                                headerGroups.map(headerGroup => (
                                    // Añadimos las propiedades al conjunto de columnas
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Recorremos cada columna del conjunto para acceder a su información
                                        headerGroup.headers.map((column) => (
                                        // Añadimos las propiedades a cada celda de la cabecera
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {
                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                            column.render("Header")
                                            }
                                        </Th>
                                        ))
                                    }
                                    </Tr>
                                ))
                            }
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {
                                // Recorremos las filas
                                page.map(row => {
                                    // Llamamos a la función que prepara la fila previo renderizado
                                    prepareRow(row);
                                    return (
                                    // Añadimos las propiedades a la fila
                                    <Tr {...row.getRowProps()}>
                                        {
                                        // Recorremos cada celda de la fila
                                        row.cells.map((cell) => {
                                            // Añadimos las propiedades a cada celda de la fila
                                            return (
                                            <Td {...cell.getCellProps()}>
                                                {
                                                // Pintamos el contenido de la celda
                                                cell.render("Cell")
                                                }
                                            </Td>
                                            );
                                        })
                                        }
                                        <Td>
                                            <PrintPDFPurchasePlan idPurchasePlan={row.values.id}/>
                                        </Td>
                                    </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>}

                {!loading && <Box paddingTop="10px" textAlign="center">
                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                </Box>}
            </Box>
        </>
    )
}

export default PurchasePlan