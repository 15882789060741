import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Button, 
    useDisclosure, InputGroup, InputLeftAddon, Stack, useToast, Select, Spacer, Flex, 
    Tooltip } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const CategoryEdit = ({idCategory, handleSubmitModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const toast = useToast()

    const navigate = useNavigate();

    const onSubmit = data => {
        const accessToken = localStorage.getItem("user")
        
        const category = {
            name: data.name
        }

        fetch("https://api.veterinariacajeao.com.ar/api/category/" + idCategory, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(category)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        toast({
            title: "¡Categoría editada!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    const handleOpen = () => {  
        const accessToken = localStorage.getItem("user")
              
        fetch("https://api.veterinariacajeao.com.ar/api/category/" + idCategory, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("name", data.name)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
    <>
        <Tooltip label='Editar'><Button size="sm" colorScheme='yellow' onClick={handleOpen}><EditIcon/></Button></Tooltip>

        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={handleClose}
            size='lg'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Editar categoría</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>

                            <InputGroup>
                                <InputLeftAddon children='Nombre' width={127} />
                                <Input ref={initialRef} placeholder='Nombre' {...register('name', {required: 'Por favor ingrese el nombre del cliente'})} />
                            </InputGroup>
                            {errors.name && <AlertPop title={errors.name.message} />}

                    </Stack>
                    <Flex p={4}>
                    <Spacer/>
                    <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                    <Button onClick={handleClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

            </ModalContent>
        </Modal>
    </>
    )
}

export default CategoryEdit