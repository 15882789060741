import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Button, 
    useDisclosure, InputGroup, InputLeftAddon, Stack, useToast, Spacer, Flex, Menu, MenuItem, MenuButton, 
    MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import PrintPDFProductsStock from './PrintPDFProductsStock'
import PrintPDFProductsSubcategory from './PrintPDFProductsSubcategory'
import PrintPDFProductsWithoutStock from './PrintPDFProductsWithoutStock'
import PrintPDFProductsMinimumStock from './PrintPDFProductsMinimumStock'
import PrintPDFProductsUndelivered from './PrintPDFProductsUndelivered'

const ProductsReports = () => {

    return (
        <>
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size='sm' colorScheme='blackAlpha' color='white'>
                    Reportes
                </MenuButton>
                <MenuList>
                    <PrintPDFProductsStock/>
                    {/*<PrintPDFProductsSubcategory/>*/}
                    <PrintPDFProductsWithoutStock/>
                    <PrintPDFProductsMinimumStock/>
                    <PrintPDFProductsUndelivered/>
                </MenuList>
            </Menu>
        </>
    )
}

export default ProductsReports