import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Input, Table,
    Thead, Tbody, Tr, Th, Td, TableContainer, useDisclosure, Stack, Box, Tooltip, HStack, CircularProgress,
    useToast } from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table"
import useColumnsProducts from "../hooks/useColumnsProducts"
import { GlobalFilter } from './GlobalFilter'
import SaleProductInfo from './SaleProductInfo'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import authService from "../services/authService"

const SaleProductAdd = ({ isDisabled, addToCart, voucher }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const columns = useColumnsProducts()
    const table = useTable({ columns, data, initialState: {pageSize: 10, pageIndex: 0, hiddenColumns: ["_id", "ubication", "code", "isActive", "avgIva", "category", "subcategory"], sortBy: [{id: "name", asc: true}]} }, useGlobalFilter, useSortBy, usePagination )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
    } = table

    const { globalFilter } = state

    const handleOpen = () => {
        const accessToken = localStorage.getItem("user")

        setLoading(true)

        fetch("https://api.veterinariacajeao.com.ar/api/product", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrProducts = data.map(product => {
            return {
                    _id: product._id,
                    id: product.id,
                    name: product.name,
                    stock: product.stock,
                    priceView: "$" + parseFloat(product.price).toFixed(2),
                    ubication: product.ubication,
                    code: product.code,
                    isActive: product.isActive.toString(),
                    avgIva: parseFloat(product.avgIva).toFixed(2)
                }
            })
            setData(arrProducts)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })

        onOpen()
    }

    const addToCartProduct = (product) => {
        addToCart(product)
    }

    return (
        <>
            <Button isDisabled={isDisabled} colorScheme='whatsapp' width={150} onClick={handleOpen}>Agregar productos</Button>
        
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cargar producto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <Box paddingTop="10px">
                                <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                            </Box>

                            {loading && <Box paddingTop="15px" textAlign='center'> 
                                <CircularProgress isIndeterminate color='green.300' />
                            </Box>}

                            {!loading && <TableContainer paddingTop="15px">
                                <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                                    <Thead>
                                        {
                                            // Recorremos las columnas que previamente definimos
                                            headerGroups.map(headerGroup => (
                                                // Añadimos las propiedades al conjunto de columnas
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                    headerGroup.headers.map((column) => (
                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                        {
                                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                                        column.render("Header")
                                                        }
                                                    </Th>
                                                    ))
                                                }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            // Recorremos las filas
                                            page.map(row => {
                                                // Llamamos a la función que prepara la fila previo renderizado
                                                prepareRow(row);
                                                return (
                                                // Añadimos las propiedades a la fila
                                                <Tr {...row.getRowProps()}>
                                                    {
                                                    // Recorremos cada celda de la fila
                                                    row.cells.map((cell) => {
                                                        // Añadimos las propiedades a cada celda de la fila
                                                        return (
                                                        <Td {...cell.getCellProps()}>
                                                            {
                                                            // Pintamos el contenido de la celda
                                                            cell.render("Cell")
                                                            }
                                                        </Td>
                                                        );
                                                    })
                                                    }
                                                    <Td>
                                                        <SaleProductInfo _idProduct={row.values._id} idProduct={row.values.id} addToCartProduct={addToCartProduct} voucher={voucher}/>
                                                    </Td>
                                                </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            <Box paddingTop="10px" textAlign="center">
                                <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                            </Box>
                        </Stack>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default SaleProductAdd